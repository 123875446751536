import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import { withRouter } from "react-router";
import AllServices from "../service/AllServices";
import { LinkContainer } from "react-router-bootstrap";

class Menu extends Component {
  render() {
    return (
      <Navbar bg="light" expand="lg" collapseOnSelect={true}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand
          className="d-none d-lg-block"
          href={`${process.env.PUBLIC_URL}/`}
        >
          MVSDA
        </Navbar.Brand>
        <div className="d-lg-none">{AllServices.getTitle()}</div>
        <div className="d-lg-none">
          {AllServices.getCreateTitle() && AllServices.inView() && (
            <LinkContainer
              type="button"
              className="btn btn-default btn-lg d-lg-none"
              to={
                `${process.env.PUBLIC_URL}` + AllServices.getBaseUrl() + "/new"
              }
            >
              <Nav.Link>+</Nav.Link>
            </LinkContainer>
          )}
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {Rest.getProfile() && (
              <React.Fragment>
                {Rest.hasAccessGroup("messages_read") && (
                  <LinkContainer to={`${process.env.PUBLIC_URL}/messages`}>
                    <Nav.Link>Messages</Nav.Link>
                  </LinkContainer>
                )}
                {Rest.hasAccessGroup("incomingcalls_read") && (
                  <LinkContainer to={`${process.env.PUBLIC_URL}/calls`}>
                    <Nav.Link>Incoming Calls</Nav.Link>
                  </LinkContainer>
                )}
                {Rest.hasAccessGroup(
                  "user_read_partial,user_read_all,user_write_all"
                ) && (
                  <LinkContainer to={`${process.env.PUBLIC_URL}/users`}>
                    <Nav.Link>Users</Nav.Link>
                  </LinkContainer>
                )}
                {Rest.hasAccessGroup(
                  "user_read_partial,user_read_all,user_write_all"
                ) && (
                  <LinkContainer to={`${process.env.PUBLIC_URL}/families`}>
                    <Nav.Link>Families</Nav.Link>
                  </LinkContainer>
                )}
                {Rest.hasAccessGroup(
                  "group_admin,membergroups_admin,access_admin,callflow_admin"
                ) && (
                  <NavDropdown title="Settings" id="basic-nav-dropdown">
                    {Rest.hasAccessGroup("group_admin") && (
                      <LinkContainer to={`${process.env.PUBLIC_URL}/groups`}>
                        <NavDropdown.Item>Groups</NavDropdown.Item>
                      </LinkContainer>
                    )}
                    {Rest.hasAccessGroup("membergroups_admin") && (
                      <LinkContainer
                        to={`${process.env.PUBLIC_URL}/membergroups`}
                      >
                        <NavDropdown.Item>Member Groups</NavDropdown.Item>
                      </LinkContainer>
                    )}
                    {Rest.hasAccessGroup("access_admin") && (
                      <LinkContainer
                        to={`${process.env.PUBLIC_URL}/contactexchanges`}
                      >
                        <NavDropdown.Item>Contact Exchanges</NavDropdown.Item>
                      </LinkContainer>
                    )}
                    {Rest.hasAccessGroup("access_admin") && (
                      <LinkContainer
                        to={`${process.env.PUBLIC_URL}/memberaccesses`}
                      >
                        <NavDropdown.Item>Member Access</NavDropdown.Item>
                      </LinkContainer>
                    )}
                    {Rest.hasAccessGroup("access_admin") && (
                      <LinkContainer
                        to={`${process.env.PUBLIC_URL}/infosharedwiths`}
                      >
                        <NavDropdown.Item>Info Shared With</NavDropdown.Item>
                      </LinkContainer>
                    )}
                    {Rest.hasAccessGroup("callflow_admin") && (
                      <LinkContainer to={`${process.env.PUBLIC_URL}/callflows`}>
                        <NavDropdown.Item>Call Flows</NavDropdown.Item>
                      </LinkContainer>
                    )}
                  </NavDropdown>
                )}
              </React.Fragment>
            )}
          </Nav>
          <Nav className="ml-auto">
            {Rest.getProfile() ? (
              <NavDropdown title={Rest.getDisplayName()} id="userdropdown">
                <LinkContainer
                  to={`${process.env.PUBLIC_URL}/users/` + Rest.getUserUnid()}
                >
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={`${process.env.PUBLIC_URL}/logout`}>
                  <NavDropdown.Item>Logout</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            ) : (
              <Nav.Link href={`${process.env.PUBLIC_URL}/login`}>
                Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const MenuWithRouter = withRouter(Menu);
export default MenuWithRouter;
