import CallFlowService from "./CallFlowService";
import CallService from "./CallService";
import GroupService from "./GroupService";
import MemberAccessService from "./MemberAccessService";
import MemberGroupService from "./MemberGroupService";
import MessageService from "./MessageService";
import UserService from "./UserService";
import FamilyService from "./FamilyService";

const services = [
  CallFlowService,
  CallService,
  FamilyService,
  GroupService,
  MemberAccessService,
  MemberGroupService,
  MessageService,
  UserService,
];

export default class AllServices {
  static getTitle() {
    let baseUrl = window.location.href;
    if (baseUrl.startsWith("http://") || baseUrl.startsWith("https://")) {
      baseUrl = baseUrl.substring(baseUrl.indexOf("/", "https://".length));
    }
    for (let i = 0; i < services.length; i++) {
      let service = services[i];
      let schema = service.getSchema();
      if (schema.baseUrl === baseUrl) {
        return schema.viewTitle || baseUrl;
      } else if (schema.baseUrl + "/new" === baseUrl) {
        return schema.createTitle || "Create" | baseUrl.substring(1);
      } else if (baseUrl.startsWith(schema.baseUrl + "/")) {
        return schema.editTitle || "Edit " + baseUrl.substring(1);
      }
    }
    return "unknown title";
  }

  static inView() {
    let baseUrl = window.location.href;
    if (baseUrl.startsWith("http://") || baseUrl.startsWith("https://")) {
      baseUrl = baseUrl.substring(baseUrl.indexOf("/", "https://".length));
    }
    for (let i = 0; i < services.length; i++) {
      let service = services[i];
      let schema = service.getSchema();
      if (schema.baseUrl === baseUrl) {
        return true;
      }
    }
    return false;
  }

  static getSchema() {
    let baseUrl = window.location.href;
    if (baseUrl.startsWith("http://") || baseUrl.startsWith("https://")) {
      baseUrl = baseUrl.substring(baseUrl.indexOf("/", "https://".length));
    }
    for (let i = 0; i < services.length; i++) {
      let service = services[i];
      let schema = service.getSchema();
      if (
        schema.baseUrl === baseUrl ||
        baseUrl.startsWith(schema.baseUrl + "/")
      ) {
        return schema;
      }
    }
    return undefined;
  }

  static getCreateTitle() {
    let schema = AllServices.getSchema();
    return schema && schema.createTitle;
  }

  static getBaseUrl() {
    return this.getSchema().baseUrl;
  }
}
