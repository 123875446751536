import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";
import UserService from "../service/UserService";
import ProfileImage from "./common/ProfileImage";

const groupStyle = {
  borderBottom: "1px dotted #000",
  fontWeight: "bold",
  width: "100%",
  display: "block",
  marginTop: "10px",
};

export default class User extends Component {
  state = {
    data: {},
    editMode: this.props.match.params.id === "new",
    streetIsSame: false,
    memberGroups: [],
    loggedInAdminGroups: [],
    groups: [],
    accesses: [],
    infosharedwiths: [],
    unids: {},
    users: [],
    newMemberId: "",
  };

  async componentDidMount() {
    if (this.props.match.params.id !== "new") {
      let promiseUser = Rest.get("/api/users/" + this.props.match.params.id);
      let promiseMemberGroups = Rest.get("/api/membergroups");
      let promiseGroups = Rest.get("/api/groups");
      let promiseAccesses = Rest.get("/api/memberaccesses");
      let promiseInfoSharedWith = Rest.get("/api/infosharedwiths");
      let promiseUsers = Rest.get("/api/users");

      let state = {};

      let response = await Promise.all([
        promiseUser,
        promiseMemberGroups,
        promiseGroups,
        promiseAccesses,
        promiseInfoSharedWith,
        promiseUsers,
      ]);
      if (response[0].status === 200) {
        let data = { ...response[0].data };
        let streetIsSame =
          data.DefaultAddress === data.StreetAddress &&
          data.DefaultOptionalLine === data.StreetOptionalLine &&
          data.DefaultCity === data.StreetCity &&
          data.DefaultStateProv === data.StreetStateProv &&
          data.DefaultPostalCode === data.StreetPostalCode;
        state.data = data;
        state.streetIsSame = streetIsSame;
      }
      if (response[2].status === 200) {
        state.groups = response[2].data;
      }
      if (response[1].status === 200) {
        state.memberGroups = response[1].data.filter(
          (mg) => mg.memberid === this.props.match.params.id
        );
        state.loggedInAdminGroups = response[1].data.filter(
          (mg) => mg.memberid === Rest.getUserUnid() && mg.isgroupadmin
        );
        if (state.groups) {
          for (let i = 0; i < state.loggedInAdminGroups.length; i++) {
            state.loggedInAdminGroups[i] = { ...state.loggedInAdminGroups[i] };
            let group = state.groups.filter(
              (g) => g.unid === state.loggedInAdminGroups[i].groupid
            );
            if (group.length > 0) {
              state.loggedInAdminGroups[i].name = group[0].name;
            }
          }
        }
      }
      if (response[3].status === 200) {
        state.accesses = Rest.ACCESS_TYPES.map((v) => {
          return { ...v, value: false };
        });
        response[3].data.forEach((a) => {
          if (a.memberid === this.props.match.params.id) {
            state.accesses.forEach((access, index) => {
              if (access.unid === a.name) {
                state.accesses[index].value = true;
              }
            });
          }
        });
      }
      if (response[4].status === 200) {
        state.infosharedwiths = response[4].data.filter(
          (isw) => isw.memberid === this.props.match.params.id
        );
      }
      if (response[5].status === 200) {
        state.users = [];
        state.unids = {};
        response[5].data.forEach((row) => {
          state.unids[row.unid] = row.FirstName + " " + row.LastName;
          state.users.push({
            unid: row.unid,
            name: row.FirstName + " " + row.LastName,
            disabled: state.infosharedwiths.find(
              (item) => item.sharedwith === row.unid
            )
              ? true
              : false,
          });
        });
      }
      this.setState(state);
    }
  }

  handleChange = ({ currentTarget: input }) => {
    let data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  renderNamePhoneEmail = () => {
    if (!this.state.editMode) {
      let data = this.state.data;
      let name =
        data.DisplayName ||
        data.FirstName +
          (data.MiddleName
            ? " " + data.MiddleName.substring(0, 1) + ". "
            : " ") +
          data.LastName;
      return (
        <table border="0">
          <tbody>
            <tr>
              <td valign="top">
                <ProfileImage
                  memberid={data.unid}
                  readonly={
                    Rest.getUserUnid() !== this.props.match.params.id &&
                    !Rest.hasAccessGroup("user_write_all")
                  }
                  photoid={data.photoid}
                  name={data.FirstName + " " + data.LastName}
                />
              </td>
              <td>
                <span style={{ ...groupStyle, fontSize: "2em" }}>{name}</span>
                {data.FamilyPhone && (
                  <div>
                    Home:{" "}
                    <a href={"tel:" + data.FamilyPhone}>{data.FamilyPhone}</a>
                  </div>
                )}
                {data.MobilePhone && (
                  <div>
                    Cell:{" "}
                    <a href={"tel:" + data.MobilePhone}>{data.MobilePhone}</a>
                  </div>
                )}
                {data.Email && (
                  <div>
                    E-mail: <a href={"mailto:" + data.Email}>{data.Email}</a>
                  </div>
                )}
                {data.Email2 && (
                  <div>
                    E-mail2: <a href={"mailto:" + data.Email2}>{data.Email2}</a>
                  </div>
                )}
                {data.Notes && <div>Notes: {data.Notes}</div>}
                {this.renderAddress()}
                <div>
                  <span style={groupStyle}>Membership</span>
                  <Field
                    name="MemberStatus"
                    type="select"
                    value={data.MemberStatus}
                    readonly={true}
                    options={UserService.getField("MemberStatus").options}
                  />
                  {(data.eadventistid &&
                    data.eadventistid > 0 &&
                    " (" + data.eadventistid + ")") ||
                    ""}
                </div>
                {this.renderPassword()}
                {(Rest.hasAccessGroup("user_write_all") ||
                  Rest.getUserUnid() === this.props.match.params.id) && (
                  <button
                    className="btn btn-primary"
                    value="Edit"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ editMode: true });
                    }}
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <React.Fragment>
          <span style={groupStyle}>Name</span>
          {["FirstName", "MiddleName", "LastName", "DisplayName"].map(
            (fieldName) => (
              <Field
                label={UserService.getField(fieldName).label}
                value={this.state.data[fieldName]}
                name={fieldName}
                key={fieldName}
                onChange={this.handleChange}
              />
            )
          )}
          <span style={groupStyle}>Phone/Email</span>
          {["FamilyPhone", "MobilePhone", "Email", "Email2", "Notes"].map(
            (fieldName) => (
              <Field
                label={UserService.getField(fieldName).label}
                value={this.state.data[fieldName]}
                name={fieldName}
                key={fieldName}
                onChange={this.handleChange}
              />
            )
          )}
          {this.renderAddress()}
          <br />
          <span style={groupStyle}>Membership</span>
          <Field
            label={UserService.getField("MemberStatus").label}
            type="select"
            value={this.state.data.MemberStatus}
            options={UserService.getField("MemberStatus").options}
            name="MemberStatus"
            key="MemberStatus"
            onChange={this.handleChange}
          />
          {(this.state.data.eadventistid &&
            this.state.data.eadventistid > 0 && (
              <Field
                label="eAdventist ID"
                value={this.state.data.eadventistid}
                readonly={true}
              />
            )) ||
            ""}
          {this.renderPassword()}
        </React.Fragment>
      );
    }
  };

  renderPassword = () => {
    let data = this.state.data;
    return (
      <React.Fragment>
        <span style={groupStyle}>Password</span>
        <Field
          label="Password"
          name="password"
          type="password"
          readonly={!this.state.editMode}
          value={data.password}
          onChange={this.handleChange}
        />
      </React.Fragment>
    );
  };

  renderAddress = () => {
    if (!this.state.editMode) {
      let data = this.state.data;
      let address = (data.DefaultAddress ||
        data.DefaultOptionalLine ||
        data.DefaultCity ||
        data.DefaultStateProv ||
        data.DefaultPostalCode) && (
        <React.Fragment>
          <div>{data.DefaultAddress}</div>
          {data.DefaultOptionalLine && <div>{data.DefaultOptionalLine}</div>}
          <div>
            {data.DefaultCity}, {data.DefaultStateProv} {data.DefaultPostalCode}
          </div>
        </React.Fragment>
      );
      let streetAddress = (data.StreetAddress ||
        data.StreetOptionalLine ||
        data.StreetCity ||
        data.StreetStateProv ||
        data.StreetPostalCode) &&
        (data.DefaultAddress !== data.StreetAddress ||
          data.DefaultOptionalLine !== data.StreetOptionalLine ||
          data.DefaultCity !== data.StreetCity ||
          data.DefaultStateProv !== data.StreetStateProv ||
          data.DefaultPostalCode !== data.StreetPostalCode) && (
          <React.Fragment>
            <div>{data.StreetAddress}</div>
            {data.StreetOptionalLine && <div>{data.StreetOptionalLine}</div>}
            <div>
              {data.StreetCity}, {data.StreetStateProv} {data.StreetPostalCode}
            </div>
          </React.Fragment>
        );

      return (
        <React.Fragment>
          <span style={groupStyle}>Address</span>
          {address}
          {streetAddress ? (
            <React.Fragment>{streetAddress}</React.Fragment>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span style={groupStyle}>Default Address</span>
          {[
            "DefaultAddress",
            "DefaultOptionalLine",
            "DefaultCity",
            "DefaultStateProv",
            "DefaultPostalCode",
          ].map((fieldName) => (
            <Field
              label={
                (UserService.getField(fieldName) &&
                  UserService.getField(fieldName).label) ||
                fieldName
              }
              value={this.state.data[fieldName]}
              name={fieldName}
              key={fieldName}
              onChange={this.handleChange}
            />
          ))}
          <span style={groupStyle}>Street Address</span>
          <Field
            type="checkbox"
            value={[
              {
                unid: "same",
                name: "Is same as above?",
                value: this.state.streetIsSame,
              },
            ]}
            name="streetIsSame"
            onChange={() =>
              this.setState({ streetIsSame: !this.state.streetIsSame })
            }
          />
          {!this.state.streetIsSame &&
            [
              "StreetAddress",
              "StreetOptionalLine",
              "StreetCity",
              "StreetStateProv",
              "StreetPostalCode",
            ].map((fieldName) => (
              <Field
                label={
                  (UserService.getField(fieldName) &&
                    UserService.getField(fieldName).label) ||
                  fieldName
                }
                value={this.state.data[fieldName]}
                name={fieldName}
                onChange={this.handleChange}
              />
            ))}
        </React.Fragment>
      );
    }
  };

  render() {
    if (
      !Rest.hasAccessGroup("user_read_partial,user_read_all,user_write_all") &&
      Rest.getUserUnid() !== this.props.match.params.id
    ) {
      this.props.history.push(`${process.env.PUBLIC_URL}/`);
      return null;
    }

    let data = {};
    let fields = UserService.getSchema().fields;
    Object.keys(fields).forEach((fieldName) => {
      let field = fields[fieldName];
      if (field.type !== "calculated") {
        data[fieldName] = this.state.data[fieldName];
      }
      if (this.state.streetIsSame && fieldName.startsWith("Default")) {
        data["Street" + fieldName.substring("Default".length)] =
          this.state.data[fieldName];
      }
    });

    return (
      <div className="m-2">
        {this.renderNamePhoneEmail()}
        {this.state.editMode && (
          <Form
            history={this.props.history}
            id={this.props.match.params.id}
            form="users"
            data={data}
          />
        )}
        {!this.state.editMode && this.renderGroups()}
        {!this.state.editMode && this.renderAccess()}
        {!this.state.editMode && this.renderInfoSharedWith()}
      </div>
    );
  }

  renderCheckbox(name, groupid, isReadOnly) {
    let memberGroup = this.state.memberGroups.filter(
      (mg) => mg.groupid === groupid
    );

    if (!memberGroup || memberGroup.length === 0) {
      memberGroup = {
        memberid: this.props.match.params.id,
        groupid: groupid,
        notify_sms: false,
        notify_email: false,
        notify_email2: false,
        notify_voice: false,
        isgroupreader: false,
        isgroupsubmitter: false,
        isgroupadmin: false,
      };
    } else if (memberGroup.length === 1) {
      memberGroup = memberGroup[0];
    } else {
      console.log(
        "Multiple member groups defined for same memberid + groupid",
        memberGroup
      );
      memberGroup = memberGroup[0];
    }

    return (
      <input
        type="checkbox"
        name={name}
        checked={memberGroup[name]}
        disabled={isReadOnly}
        onChange={async ({ currentTarget: input }) => {
          memberGroup[name] = !memberGroup[name];
          if (memberGroup.unid) {
            memberGroup = (
              await Rest.post(
                "/api/membergroups/" + memberGroup.unid,
                memberGroup
              )
            ).data;
            let memberGroups = [...this.state.memberGroups];
            for (let i = 0; i < memberGroups.length; i++) {
              if (memberGroups[i].unid === memberGroup.unid) {
                memberGroups[i] = { ...memberGroup };
                break;
              }
            }
            this.setState({ memberGroups });
          } else {
            memberGroup = (await Rest.post("/api/membergroups", memberGroup))
              .data;
            let memberGroups = [...this.state.memberGroups, memberGroup];
            this.setState({ memberGroups });
          }
        }}
      />
    );
  }

  isInGroup = (groupunid) => {
    let entry = this.state.memberGroups.find((mg) => mg.groupid === groupunid);
    if (entry) {
      return true;
    } else {
      return false;
    }
  };

  renderInfoSharedWith = () => {
    let infosharedwiths = this.state.infosharedwiths;
    let sharedWith = infosharedwiths.map((isw) => (
      <div key={isw.unid}>
        {this.state.unids[isw.sharedwith] || isw.sharedwith}
        {this.props.match.params.id === Rest.getUserUnid() && (
          <button
            className="btn btn-danger"
            onClick={async () => {
              await Rest.delete("/api/infosharedwiths/" + isw.unid);
              let infosharedwiths = [...this.state.infosharedwiths];
              infosharedwiths = infosharedwiths.filter(
                (value) => value.sharedwith !== isw.sharedwith
              );
              let users = [...this.state.users];
              users = users.map((user) => {
                return {
                  ...user,
                  disabled:
                    user.unid === isw.sharedwith ? false : user.disabled,
                };
              });
              this.setState({ infosharedwiths, users });
            }}
          >
            Delete
          </button>
        )}
      </div>
    ));
    return (
      <React.Fragment>
        <h3>Shared With</h3>
        <Field
          name="SharedWith"
          label="Shared With"
          type="select"
          readonly={
            !Rest.hasAccessGroup("user_write_all") &&
            this.props.match.params.id !== Rest.getUserUnid()
          }
          value={this.state.data.SharedWith}
          onChange={async ({ currentTarget: input }) => {
            let data = { ...this.state.data };
            data.SharedWith = input.value;
            await Rest.post("/api/users/" + this.props.match.params.id, {
              unid: this.props.match.params.id,
              SharedWith: input.value,
            });
            this.setState({ data });
          }}
          options={[
            { unid: "everyone", name: "Everyone" },
            { unid: "groupmembers", name: "Group Members" },
            { unid: "administration", name: "Administration" },
          ]}
        />
        {sharedWith.length === 0 ? "No individual" : sharedWith}
        {this.props.match.params.id === Rest.getUserUnid() && (
          <div>
            <Field
              style={{ width: "*" }}
              name="newMemberId"
              type="select"
              sorted={true}
              options={this.state.users}
              value={this.state.newMemberId}
              onChange={({ currentTarget: input }) => {
                this.setState({ newMemberId: input.value });
              }}
            />
            &nbsp;
            <button
              className="btn btn-secondary"
              onClick={async () => {
                let mg = {
                  memberid: this.props.match.params.id,
                  sharedwith: this.state.newMemberId,
                };
                mg = (await Rest.post("/api/infosharedwiths", mg)).data;
                let infosharedwiths = [...this.state.infosharedwiths];
                infosharedwiths.push(mg);
                let users = [...this.state.users];
                users = users.map((user) => {
                  return {
                    ...user,
                    disabled:
                      user.unid === this.state.newMemberId
                        ? true
                        : user.disabled,
                  };
                });
                this.setState({ infosharedwiths, users, newMemberId: "" });
              }}
            >
              Add
            </button>
          </div>
        )}
      </React.Fragment>
    );
  };

  hasGroupEditAccess = (groupUnid) => {
    if (this.props.match.params.id === Rest.getUserUnid()) {
      return true;
    }
    if (Rest.hasAccessGroup("group_admin")) {
      return true;
    }
    let entry = this.state.loggedInAdminGroups.filter(
      (mg) => mg.groupid === groupUnid
    );
    if (entry.length > 0) {
      return true;
    }

    return false;
  };

  getGroupNameFromGroupId = (groupid) => {
    let memberGroup = this.state.groups.filter((g) => g.unid === groupid);
    console.log("STEP1", this.state.groups, groupid, memberGroup);
    if (memberGroup && memberGroup[0]) {
      return memberGroup[0].name;
    }
    return null;
  };

  renderGroups = () => {
    let hasGroupAdmin = Rest.hasAccessGroup("group_admin");
    let groups =
      this.state.groups &&
      this.state.groups.filter(
        (group) =>
          group.subscribable === "Y" ||
          this.isInGroup(group.unid) ||
          hasGroupAdmin
      );
    if (groups.length === 0) {
      return null;
    }
    let groupsHash = {};
    groups.forEach((g) => {
      groupsHash[g.unid] = g.unid;
    });
    let newGroups = [...this.state.loggedInAdminGroups];
    newGroups = newGroups
      .filter((g) => !groupsHash[g.groupid])
      .map((g) => {
        return {
          unid: g.groupid,
          name: this.getGroupNameFromGroupId(g.groupid),
        };
      });
    groups = [...groups, ...newGroups];
    groups = groups.sort((g1, g2) =>
      g1.name > g2.name ? 1 : g1.name < g2.name ? -1 : 0
    );
    console.log("groups", groups);
    return (
      <React.Fragment>
        <hr />
        <h3>Notification Preferences</h3>
        <table border="1">
          <thead>
            {(this.state.memberGroups.length > 0 || hasGroupAdmin) && (
              <tr>
                <th>&nbsp;</th>
                <th colSpan="4">Notify by</th>
                <th colSpan="3">Permissions</th>
              </tr>
            )}
            <tr>
              <th>Name</th>
              <th>SMS</th>
              <th>E-mail</th>
              <th>E-mail2</th>
              <th>Call</th>
              {(this.state.memberGroups.length > 0 || hasGroupAdmin) && (
                <React.Fragment>
                  <th>Reader</th>
                  <th>Submitter</th>
                  <th>Admin</th>
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {groups &&
              groups.map((group) => (
                <tr key={group.unid}>
                  <td>{group.name}</td>
                  <td>
                    {this.renderCheckbox(
                      "notify_sms",
                      group.unid,
                      !this.hasGroupEditAccess(group.unid)
                    )}
                  </td>
                  <td>
                    {this.renderCheckbox(
                      "notify_email",
                      group.unid,
                      !this.hasGroupEditAccess(group.unid)
                    )}
                  </td>
                  <td>
                    {this.renderCheckbox(
                      "notify_email2",
                      group.unid,
                      !this.hasGroupEditAccess(group.unid)
                    )}
                  </td>
                  <td>
                    {this.renderCheckbox(
                      "notify_voice",
                      group.unid,
                      !this.hasGroupEditAccess(group.unid)
                    )}
                  </td>
                  <td>
                    {this.renderCheckbox(
                      "isgroupreader",
                      group.unid,
                      !hasGroupAdmin
                    )}
                  </td>
                  <td>
                    {this.renderCheckbox(
                      "isgroupsubmitter",
                      group.unid,
                      !hasGroupAdmin
                    )}
                  </td>
                  <td>
                    {this.renderCheckbox(
                      "isgroupadmin",
                      group.unid,
                      !hasGroupAdmin
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  renderAccess = () => {
    let hasAccessAdmin = Rest.hasAccessGroup("access_admin");
    let accesses = hasAccessAdmin
      ? this.state.accesses
      : this.state.accesses.filter((a) => a.value === true);
    if (accesses.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <hr />
        <h3>Access</h3>
        <Field
          type="checkbox"
          readonly={!hasAccessAdmin}
          value={accesses}
          name="accesses"
          onChange={async (accesses) => {
            for (let i = 0; i < accesses.length; i++) {
              if (accesses[i].value && !this.state.accesses[i].value) {
                //add
                Rest.post("/api/memberaccesses", {
                  memberid: this.state.data.unid,
                  name: accesses[i].unid,
                });
              } else if (!accesses[i].value && this.state.accesses[i].value) {
                //remove
                let response = await Rest.get("/api/memberaccesses");
                if (response.status === 200) {
                  let memberAccesses = response.data;
                  let memberAccess = memberAccesses.find(
                    (ma) => ma.name === accesses[i].unid
                  );
                  if (memberAccess) {
                    await Rest.delete(
                      "/api/memberaccesses/" + memberAccess.unid
                    );
                  }
                }
              }
            }
            this.setState({ accesses });
          }}
          error={this.state.error}
        />
      </React.Fragment>
    );
  };
}
