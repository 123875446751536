import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import CallService from "../service/CallService";
import Table from "./common/Table";

export default class CallFlows extends Component {
  state = { rows: [] };
  async componentDidMount() {
    let response = await Rest.get("/api/calls");
    if (response.status === 200) {
      let rows = response.data.sort((r1, r2) =>
        r1.created < r2.created ? 1 : r1.created > r2.created ? -1 : 0
      );
      this.setState({ rows });
    }
  }

  render() {
    return (
      <Table
        schema={CallService.getSchema()}
        rows={this.state.rows}
        history={this.props.history}
      />
    );
  }
}
