import React from "react";
import "./App.css";
import Menu from "./components/Menu";
import Message from "./components/Message";
import Messages from "./components/Messages";
import ContactExchange from "./components/ContactExchange";
import ContactExchanges from "./components/ContactExchanges";
import Group from "./components/Group";
import Groups from "./components/Groups";
import MemberAccess from "./components/MemberAccess";
import MemberAccesses from "./components/MemberAccesses";
import InfoSharedWith from "./components/InfoSharedWith";
import InfoSharedWiths from "./components/InfoSharedWiths";
import CallFlow from "./components/CallFlow";
import CallFlows from "./components/CallFlows";
import Call from "./components/Call";
import Calls from "./components/Calls";
import MemberGroup from "./components/MemberGroup";
import MemberGroups from "./components/MemberGroups";
import User from "./components/User";
import Users from "./components/Users";
import Family from "./components/Family";
import Families from "./components/Families";
import LoginForm from "./components/LoginForm";
import LogoutForm from "./components/LogoutForm";
import { Route, Redirect, Switch } from "react-router-dom";
import Rest from "./utils/rest";

function App() {
  return (
    <React.Fragment>
      <Menu />
      {Rest.isLoggedIn() && (
        <Switch>
          {Rest.hasAccessGroup("messages_read") && (
            <Route
              path={`${process.env.PUBLIC_URL}/messages/:id`}
              component={Message}
            />
          )}
          {Rest.hasAccessGroup("messages_read") && (
            <Route
              path={`${process.env.PUBLIC_URL}/messages`}
              component={Messages}
            />
          )}
          {Rest.hasAccessGroup("group_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/groups/:id`}
              component={Group}
            />
          )}
          {Rest.hasAccessGroup("group_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/groups`}
              component={Groups}
            />
          )}
          {Rest.hasAccessGroup("access_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/memberaccesses/:id`}
              component={MemberAccess}
            />
          )}
          {Rest.hasAccessGroup("access_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/memberaccesses`}
              component={MemberAccesses}
            />
          )}
          {Rest.hasAccessGroup("access_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/contactexchanges/:id`}
              component={ContactExchange}
            />
          )}
          {Rest.hasAccessGroup("access_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/contactexchanges`}
              component={ContactExchanges}
            />
          )}
          {Rest.hasAccessGroup("access_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/infosharedwiths/:id`}
              component={InfoSharedWith}
            />
          )}
          {Rest.hasAccessGroup("access_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/infosharedwiths`}
              component={InfoSharedWiths}
            />
          )}
          {Rest.hasAccessGroup("incomingcalls_read") && (
            <Route
              path={`${process.env.PUBLIC_URL}/calls/:id`}
              component={Call}
            />
          )}
          {Rest.hasAccessGroup("incomingcalls_read") && (
            <Route path={`${process.env.PUBLIC_URL}/calls`} component={Calls} />
          )}
          {Rest.hasAccessGroup("callflow_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/callflows/:id`}
              component={CallFlow}
            />
          )}
          {Rest.hasAccessGroup("callflow_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/callflows`}
              component={CallFlows}
            />
          )}
          {Rest.hasAccessGroup("membergroups_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/membergroups/:id`}
              component={MemberGroup}
            />
          )}
          {Rest.hasAccessGroup("membergroups_admin") && (
            <Route
              path={`${process.env.PUBLIC_URL}/membergroups`}
              component={MemberGroups}
            />
          )}
          <Route
            path={`${process.env.PUBLIC_URL}/users/:id`}
            component={User}
          />
          {Rest.hasAccessGroup(
            "user_read_partial,user_read_all,user_write_all"
          ) && (
            <Route path={`${process.env.PUBLIC_URL}/users`} component={Users} />
          )}
          <Route
            path={`${process.env.PUBLIC_URL}/families/:id`}
            component={Family}
          />
          {Rest.hasAccessGroup(
            "user_read_partial,user_read_all,user_write_all"
          ) && (
            <Route
              path={`${process.env.PUBLIC_URL}/families`}
              component={Families}
            />
          )}
          <Route
            path={`${process.env.PUBLIC_URL}/logout`}
            component={LogoutForm}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/login`}
            component={LoginForm}
          />
          {Rest.hasAccessGroup("messages_read") && (
            <Redirect from="/" to={`${process.env.PUBLIC_URL}/messages`} />
          )}
          {Rest.hasAccessGroup(
            "user_read_partial,user_read_all,user_write_all"
          ) && <Redirect from="/" to={`${process.env.PUBLIC_URL}/users`} />}
          <Redirect
            from="/"
            to={`${process.env.PUBLIC_URL}/users/` + Rest.getUserUnid()}
          />
        </Switch>
      )}
      {!Rest.isLoggedIn() && (
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL}/login`}
            component={LoginForm}
          />
          <Redirect from="/" to={`${process.env.PUBLIC_URL}/login`} />
        </Switch>
      )}
    </React.Fragment>
  );
}

export default App;
