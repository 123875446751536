import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import utils from "../utils/utils";

export default class CallFlow extends Component {
  state = {
    created: "",
    fromphone: "",
    callsid: "",
    sequence: [],
    unid: "",
    callflow: [],
    transcription: null,
    recordingurl: null,
  };

  async componentDidMount() {
    if (this.props.match.params.id !== "new") {
      let response = await Rest.get("/api/calls/" + this.props.match.params.id);
      if (response.status === 200) {
        let state = { ...response.data };
        this.setState(state);
      }
    }
  }

  renderRows = () => {
    let ret = [];
    for (let i = 0; i < this.state.sequence.length; i++) {
      let seqRow = this.state.sequence[i];
      let cf = this.state.callflow[seqRow];
      let nextSeqRow =
        i + 1 < this.state.sequence.length
          ? String(this.state.sequence[i + 1])
          : "-1";

      if (cf.type === "speak" || cf.type === "hangup") {
        ret.push(
          <tr key={i}>
            <td className="p-2">{cf.text}</td>
            <td className="p-2">&nbsp;</td>
          </tr>
        );
      } else if (cf.type === "pause") {
        ret.push(
          <tr key={i}>
            <td className="p-2">Pause 2 seconds</td>
            <td className="p-2">&nbsp;</td>
          </tr>
        );
      } else if (cf.type === "dial") {
        ret.push(
          <tr key={i}>
            <td className="p-2">Dial</td>
            <td className="p-2">{cf.phone}</td>
          </tr>
        );
      } else if (cf.type === "voicemail") {
        ret.push(
          <tr key={i}>
            <td className="p-2">Voicemail</td>
            <td className="p-2">
              {this.state.transcription &&
                typeof this.state.transcription === "object" && (
                  <div>{this.state.transcription.msg}</div>
                )}
              {this.state.recordingurl && (
                <a
                  href={this.state.recordingurl + ".mp3"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recording
                </a>
              )}
            </td>
          </tr>
        );
      } else if (cf.type === "response") {
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].forEach((r) => {
          if (cf.responses[r].text && nextSeqRow === cf.responses[r].goto) {
            ret.push(
              <tr key={i}>
                <td className="p-2">{cf.text}</td>
                <td className="p-2">
                  {String(r) + ": " + cf.responses[r].text}
                </td>
              </tr>
            );
          }
        });
      } else {
        ret.push("UNKNOWN");
      }
    }
    return ret;
  };

  renderCallFlow() {
    return (
      <React.Fragment>
        <Field
          name="created"
          readonly={true}
          label="Created"
          value={utils.formatMMDDYYY_HHMM(this.state.created)}
        />
        <Field
          name="from"
          readonly={true}
          label="From"
          type="phone"
          value={utils.formatPhone(this.state.fromphone)}
        />
        <table border="1">
          <thead>
            <tr>
              <th className="p-2">Text</th>
              <th className="p-2">Response</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="m-2">
        {this.renderCallFlow()}
        <button
          className="btn btn-secondary mt-2"
          onClick={async (e) => {
            e.preventDefault();
            this.props.history.push(`${process.env.PUBLIC_URL}/calls`);
          }}
        >
          Close
        </button>
      </div>
    );
  }
}
