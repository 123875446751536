import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import { HorizontalBar } from "react-chartjs-2";

const VALID_SMS_CHARS =
  "_ !\"#%&'()*+,-./0123456789:;<=>?ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const SMS_CHARACTER_LIMIT = 160;
const CALL_CHARACTER_LIMIT = 250;

export default class Message extends Component {
  state = {
    groups: [],
    members: [],
    unids: {},
    groupid: "",
    authorid: "",
    message: {
      sms_text: "",
      call_text: "",
      email_subject: "",
      email_body: "",
      answer_1: "",
      answer_2: "",
      answer_3: "",
      answer_4: "",
      answer_5: "",
      answer_6: "",
      answer_7: "",
      answer_8: "",
      answer_9: "",
      answer_0: "",
      confirm_msg_1: "",
      confirm_msg_2: "",
      confirm_msg_3: "",
      confirm_msg_4: "",
      confirm_msg_5: "",
      confirm_msg_6: "",
      confirm_msg_7: "",
      confirm_msg_8: "",
      confirm_msg_9: "",
      confirm_msg_0: "",
    },
    responses: [],
    sendMessageVia: [
      { unid: "sms", name: "SMS", value: false },
      { unid: "call", name: "Voice", value: false },
      { unid: "email", name: "E-mail", value: false },
    ],
    responseType: "",
    error: {},
  };

  async componentDidMount() {
    let promiseGroups = Rest.get("/api/groups");
    let promiseMembers = Rest.get("/api/users");
    let response = await Promise.all([promiseGroups, promiseMembers]);
    let unids = {};
    if (response[0].status === 200) {
      this.setState({ groups: response[0].data });
      response[0].data.forEach((group) => {
        unids[group.unid] = group.name;
      });
    }
    if (response[1].status === 200) {
      this.setState({ members: response[1].data });
      response[1].data.forEach((member) => {
        unids[member.unid] = member.FirstName + " " + member.LastName;
      });
    }
    this.setState({ unids });

    let id = this.props.match.params.id;
    if (id !== "new") {
      let response = await Rest.get("/api/messages/" + id);
      this.setState({ ...response.data });
    } else {
      this.setState({ authorid: Rest.getUserUnid() });
    }
  }

  handleTextFieldSmsText = ({ currentTarget: input }) => {
    let newText = "";
    input.value.split("").forEach((ch) => {
      if (VALID_SMS_CHARS.indexOf(ch) !== -1) {
        newText += ch;
      }
    });
    if (newText.length > SMS_CHARACTER_LIMIT) {
      newText = newText.substring(0, SMS_CHARACTER_LIMIT);
    }
    let message = { ...this.state.message };
    message[input.name] = newText;
    let error = { ...this.state.error };
    delete error[input.name];
    this.setState({ message, error });
  };

  handleTextField = ({ currentTarget: input }) => {
    let message = { ...this.state.message };
    message[input.name] = input.value;
    let error = { ...this.state.error };
    delete error[input.name];
    this.setState({ message, error });
  };

  handleTextFieldCall = ({ currentTarget: input }) => {
    let message = { ...this.state.message };
    if (input.value.length > CALL_CHARACTER_LIMIT) {
      message[input.name] = input.value.substring(0, CALL_CHARACTER_LIMIT);
    } else {
      message[input.name] = input.value;
    }
    let error = { ...this.state.error };
    delete error[input.name];
    this.setState({ message, error });
  };

  formValidated = () => {
    let error = {};

    //check if "to" is set
    if (!this.state.groupid) {
      error.to = '"To" field needs to be filled in';
    }

    //check message fields
    let anyChecked = false;
    if (
      this.state.sendMessageVia.forEach((smv) => {
        if (smv.value) {
          anyChecked = true;
          if (smv.unid === "sms" && !this.state.message.sms_text) {
            error.sms_text = "SMS field need to be filled out";
          }
          if (smv.unid === "call" && !this.state.message.call_text) {
            error.call_text = "Voice field need to be filled out";
          }
          if (smv.unid === "email" && !this.state.message.email_subject) {
            error.email_subject = "Email subject field need to be filled out";
          }
          if (smv.unid === "email" && !this.state.message.email_body) {
            error.email_body = "Email body field need to be filled out";
          }
        }
      })
    );
    if (!anyChecked) {
      error.sendmessagevia =
        "At least one of the SMS/Voice/E-mail options need to be selected";
    }

    //check response type
    if (!this.state.responseType) {
      error.responseType = "Select an apprioprate response type";
    }

    for (let i = 0; i < 10; i++) {
      if (
        this.state.message["answer_" + i] &&
        !this.state.message["confirm_msg_" + i]
      ) {
        error["answer_" + i] =
          '"Reply message to user" needs to be filled in for each requested response';
      } else if (
        !this.state.message["answer_" + i] &&
        this.state.message["confirm_msg_" + i]
      ) {
        error["confirm_msg_" + i] =
          '"Requested Response" field need to be filled in if "Reply message to user" field has a value';
      }
    }
    this.setState({ error });
    return Object.keys(error).length === 0;
  };

  render() {
    let readOnly = this.props.match.params.id !== "new";
    return (
      <div className="m-2">
        <Field
          label="From"
          readonly={true}
          value={this.state.unids[this.state.authorid]}
          name="from"
          onChange={this.handleTextField}
          error={this.state.error}
        />
        <Field
          type="select"
          label="To"
          readonly={readOnly}
          value={this.state.groupid}
          name="to"
          onChange={({ currentTarget: input }) => {
            this.setState({ groupid: input.value });
          }}
          options={this.state.groups}
          error={this.state.error}
        />
        {!readOnly && (
          <Field
            type="checkbox"
            label="Send message via"
            value={this.state.sendMessageVia}
            name="sendmessagevia"
            onChange={(sendMessageVia) => {
              let error = { ...this.state.error };
              delete error.sendmessagevia;
              this.setState({ sendMessageVia, error });
            }}
            error={this.state.error}
          />
        )}
        {this.state.sendMessageVia.find(
          (t) =>
            (t.unid === "sms" && t.value) ||
            (readOnly && this.state.message.sms_text)
        ) && (
          <React.Fragment>
            <Field
              label="SMS message"
              type="textarea"
              readonly={readOnly}
              value={this.state.message.sms_text}
              name="sms_text"
              onChange={this.handleTextFieldSmsText}
              error={this.state.error}
            />
            {!readOnly &&
            SMS_CHARACTER_LIMIT - this.state.message.sms_text.length > 0 ? (
              <div class="form-group">
                <label></label>
                <span>
                  {SMS_CHARACTER_LIMIT - this.state.message.sms_text.length} SMS
                  characters remaining
                </span>
              </div>
            ) : (
              !readOnly && (
                <div class="form-group">
                  <label></label>
                  <span
                    style={{ backgroundColor: "#FF0000", fontWeight: "bold" }}
                  >
                    REACHED SMS character limit
                  </span>
                </div>
              )
            )}
          </React.Fragment>
        )}
        {this.state.sendMessageVia.find(
          (t) =>
            (t.unid === "call" && t.value) ||
            (readOnly && this.state.message.call_text)
        ) && (
          <React.Fragment>
            <Field
              label="Call message"
              type="textarea"
              readonly={readOnly}
              value={this.state.message.call_text}
              name="call_text"
              onChange={this.handleTextFieldCall}
              error={this.state.error}
            />
            {!readOnly &&
            CALL_CHARACTER_LIMIT - this.state.message.call_text.length > 0 ? (
              <div class="form-group">
                <label></label>
                <span>
                  {CALL_CHARACTER_LIMIT - this.state.message.call_text.length}{" "}
                  call characters remaining
                </span>
              </div>
            ) : (
              !readOnly && (
                <div class="form-group">
                  <label></label>
                  <span
                    style={{ backgroundColor: "#FF0000", fontWeight: "bold" }}
                  >
                    REACHED call character limit
                  </span>
                </div>
              )
            )}
          </React.Fragment>
        )}
        {this.state.sendMessageVia.find(
          (t) =>
            (t.unid === "email" && t.value) ||
            (this.state.message.email_subject && readOnly)
        ) && (
          <React.Fragment>
            <Field
              label="Email subject"
              type="text"
              readonly={readOnly}
              value={this.state.message.email_subject}
              name="email_subject"
              onChange={this.handleTextField}
              error={this.state.error}
            />
            <Field
              label="Email Body"
              type="textarea"
              readonly={readOnly}
              value={this.state.message.email_body}
              name="email_body"
              onChange={this.handleTextField}
              error={this.state.error}
            />
          </React.Fragment>
        )}
        {!readOnly && (
          <Field
            name="responseType"
            label="Response?"
            value={this.state.responseType}
            type="select"
            options={[
              { unid: "none", name: "No Response Requested" },
              { unid: "yesno", name: "Yes/No response" },
              { unid: "yesnomaybe", name: "Yes/No/Maybe" },
              { unid: "custom", name: "Custom response" },
            ]}
            onChange={({ currentTarget: input }) => {
              let state = { ...this.state };
              for (let i = 0; i < 10; i++) {
                state.message["answer_" + i] = "";
                state.message["confirm_msg_" + i] = "";
              }
              if (input.value === "yesno" || input.value === "yesnomaybe") {
                state.message.answer_1 = "yes";
                state.message.answer_2 = "no";
                state.message.confirm_msg_1 = "Thanks for confirming";
                state.message.confirm_msg_2 = "We will miss you";
              }
              if (input.value === "yesnomaybe") {
                state.message.answer_3 = "maybe";
                state.message.confirm_msg_3 = "Thanks for letting us know";
              }
              state.responseType = input.value;
              this.setState(state);
            }}
            error={this.state.error}
          />
        )}
        <table border="1" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Requested Response</th>
              <th>Reply message to user</th>
            </tr>
          </thead>
          <tbody>
            {this.state.responseType !== "" &&
              this.state.responseType !== "none" &&
              (this.state.responseType === "yesno"
                ? [1, 2]
                : this.state.responseType === "yesnomaybe"
                ? [1, 2, 3]
                : [1, 2, 3, 4, 5, 6, 7, 8]
              ).map((index) => {
                if (readOnly && !this.state.message["answer_" + index]) {
                  return null;
                }
                return (
                  <tr key={index}>
                    <td>
                      <Field
                        name={"display_index_" + index}
                        value={index}
                        readonly={true}
                        error={this.state.error}
                      />
                    </td>
                    <td>
                      <Field
                        name={"answer_" + index}
                        readonly={
                          readOnly ||
                          this.state.responseType === "yesno" ||
                          this.state.responseType === "yesnomaybe"
                        }
                        value={this.state.message["answer_" + index]}
                        onChange={({ currentTarget: input }) => {
                          let message = { ...this.state.message };
                          message[input.name] = input.value;
                          let error = { ...this.state.error };
                          delete error[input.name];
                          this.setState({ message, error });
                        }}
                        error={this.state.error}
                      />
                    </td>
                    <td>
                      <Field
                        name={"confirm_msg_" + index}
                        readonly={readOnly}
                        value={this.state.message["confirm_msg_" + index]}
                        onChange={({ currentTarget: input }) => {
                          let message = { ...this.state.message };
                          message[input.name] = input.value;
                          let error = { ...this.state.error };
                          delete error[input.name];
                          this.setState({ message, error });
                        }}
                        error={this.state.error}
                      />
                    </td>
                  </tr>
                );
              })}
            <tr key="9">
              <td>
                <Field
                  name="display_index_9"
                  value="9"
                  readonly={true}
                  error={this.state.error}
                />
              </td>
              <td>
                <Field
                  name={"answer_9"}
                  readonly={true}
                  value={'"settings" to opt out'}
                  error={this.state.error}
                />
              </td>
              <td>
                <Field
                  name={"confirm_msg_9"}
                  readonly={true}
                  value=""
                  error={this.state.error}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        {readOnly && this.renderResponses()}
        <button
          className="btn btn-primary mr-2"
          disabled={false}
          onClick={async (e) => {
            e.preventDefault();
            let id = this.props.match.params.id;
            if (id !== "new" || this.formValidated()) {
              let data = {
                authorid: this.state.authorid,
                message: this.state.message,
                groupid: this.state.groupid,
              };

              let response = await Rest.post("/api/messages/" + id, data);
              if (response.status === 200) {
                this.props.history.push(`${process.env.PUBLIC_URL}/messages`);
              }
            }
          }}
        >
          {readOnly ? "Resend to non-responders" : "Send"}
        </button>
        <button
          className="btn btn-secondary mr-2"
          onClick={async (e) => {
            e.preventDefault();
            this.props.history.push(`${process.env.PUBLIC_URL}/messages`);
          }}
        >
          Cancel
        </button>
      </div>
    );
  }

  renderResponses = () => {
    let answersTotal = {};
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].forEach((index) => {
      let answer = this.state.message["answer_" + index];
      if (answer) {
        answer = answer.toLowerCase().trim();
        answersTotal[answer] = 0;
      }
    });
    answersTotal["no answer"] = 0;
    answersTotal["unknown answer"] = 0;

    return (
      <React.Fragment>
        <h3>Responses</h3>
        <table border="1">
          <thead>
            <tr>
              <th>From</th>
              <th>Answer</th>
              <th>Raw Data</th>
            </tr>
          </thead>
          <tbody>
            {this.state.responses.map((response) => {
              let lastValue = "";
              let lastAnswer = "";
              let hasResponse = false;
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].forEach((index) => {
                let answer = this.state.message["answer_" + index];
                if (answer) {
                  answer = answer.toLowerCase().trim();
                  let responseKeys = Object.keys(response.response);
                  for (let i = responseKeys.length - 1; i >= 0; i--) {
                    let key = responseKeys[i];
                    let body = response.response[key].Body;
                    if (typeof body === "string") {
                      body = [body];
                    }
                    if (
                      body &&
                      body[body.length - 1].toLowerCase().trim() === answer
                    ) {
                      if (!lastValue) {
                        lastValue = body[body.length - 1];
                        lastAnswer = answer;
                      }
                    }
                    if (body && body[body.length - 1]) {
                      hasResponse = true;
                    }
                  }
                }
              });
              if (lastValue) {
                answersTotal[lastAnswer]++;
              } else if (hasResponse) {
                answersTotal["unknown answer"]++;
              } else {
                answersTotal["no answer"]++;
              }
              return (
                <tr key={response.unid}>
                  <td>{this.state.unids[response.toid] || response.toid}</td>
                  <td>{lastValue}</td>
                  <td>
                    {response.response &&
                      Object.keys(response.response).map((key) => {
                        let body = response.response[key].Body;
                        if (typeof body === "string") {
                          body = [body];
                        }
                        return (
                          <table border="1">
                            <tbody>
                              <tr>
                                <td>{key}</td>
                                <td>
                                  <table border="1">
                                    <tbody>
                                      {body &&
                                        body.map((b, i) => (
                                          <tr key={i}>
                                            <td>{b}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.renderChart(answersTotal)}
      </React.Fragment>
    );
  };

  renderChart = (keyvalues) => {
    let keys = [];
    let values = [];
    let color = ["green", "red", "blue", "yellow", "orange", "brown"];
    Object.keys(keyvalues).forEach((key) => {
      keys.push(key);
      values.push(keyvalues[key]);
    });
    return (
      <div style={{ height: String(50 * keyvalues.length) + "px" }}>
        <HorizontalBar
          legend={{ display: false }}
          data={{
            title: "Responses",
            labels: keys,
            datasets: [
              {
                data: values,
                backgroundColor: color,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>
    );
  };
}
