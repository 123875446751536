import Service from "./Service";
import React from "react";
import ProfileImage from "../components/common/ProfileImage";
import Rest from "../utils/rest";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/users`,
  createTitle: Rest.hasAccessGroup("user_write_all")
    ? "Create User"
    : undefined,
  viewTitle: "Users",
  editTitle: "Edit User",
  columns: [
    "viewPhoto",
    "viewName",
    "viewPhone",
    "viewEmail",
    "viewAddress",
    "MemberStatus",
  ],
  viewGroups: ["MemberStatus"],
  fields: {
    eadventistid: {
      label: "eAdventist ID",
      readonly: true,
    },
    FirstName: {
      label: "First Name",
      display: "d-none d-sm-table-cell",
    },
    MiddleName: {
      label: "Middle Name",
    },
    LastName: {
      label: "Last Name",
    },
    Suffix: {
      label: "Suffix",
    },
    DisplayName: {
      label: "Display Name",
    },
    FamilyPhone: {
      label: "Phone Number",
    },
    DefaultAddress: {
      label: "Address",
    },
    DefaultOptionalLine: {
      label: "Address 2",
    },
    DefaultCity: {
      label: "City",
    },
    DefaultStateProv: {
      label: "State",
    },
    DefaultPostalCode: {
      label: "PostalCode",
    },
    Email: {
      label: "Email",
    },
    Email2: {
      label: "Email2",
    },
    Notes: {
      label: "Notes",
      type: "textarea",
    },
    MobilePhone: {
      label: "Mobile Phone",
    },
    password: {
      label: "Password",
      type: "password",
    },
    MemberStatus: {
      label: "Member Status",
      type: "select",
      options: [
        { unid: "mvsda_member", name: "MVSDA Member" },
        { unid: "sda_member", name: "SDA Member (not MVSDA)" },
        { unid: "visitorfriend", name: "Visitor/Friend" },
        { unid: "family", name: "Family related" },
        { unid: "mailinglist_only", name: "Mailing List Only" },
        { unid: "other", name: "Other" },
        { unid: "", name: "unspecified" },
      ],
    },
    viewEmail: {
      label: "Email",
      type: "calculated",
      value: (entry) => (
        <React.Fragment>
          {entry.Email &&
            (entry.Email === "********" ? (
              entry.Email
            ) : (
              <a href={"mailto:" + entry.Email}>{entry.Email}</a>
            ))}
          {entry.Email2 &&
            (entry.Email2 === "********" ? (
              entry.Email2
            ) : (
              <React.Fragment>
                <br />
                <a href={"mailto:" + entry.Email2}>{entry.Email2}</a>
              </React.Fragment>
            ))}
        </React.Fragment>
      ),
    },
    viewPhone: {
      label: "Phone",
      type: "calculated",
      value: (entry) => (
        <React.Fragment>
          {entry.FamilyPhone && (
            <div>
              H:{" "}
              {entry.FamilyPhone === "********" ? (
                entry.FamilyPhone
              ) : (
                <a href={"tel:" + entry.FamilyPhone}>{entry.FamilyPhone}</a>
              )}
            </div>
          )}
          {entry.MobilePhone && (
            <div>
              C:{" "}
              {entry.MobilePhone === "********" ? (
                entry.MobilePhone
              ) : (
                <a href={"tel:" + entry.MobilePhone}>{entry.MobilePhone}</a>
              )}
            </div>
          )}
        </React.Fragment>
      ),
    },
    viewPhoto: {
      label: "",
      type: "calculated",
      link: false,
      value: (entry) => (
        <ProfileImage
          readonly={true}
          size={50}
          memberid={entry.unid}
          photoid={entry.photoid}
          name={entry.FirstName + " " + entry.LastName}
        />
      ),
    },
    viewAddress: {
      label: "Address",
      type: "calculated",
      value: (entry) =>
        (entry.DefaultAddress ||
          entry.DefaultCity ||
          entry.DefaultStateProv ||
          entry.DefaultPostalCode) && (
          <React.Fragment>
            <div>{entry.DefaultAddress}</div>
            <div>
              {entry.DefaultCity}, {entry.DefaultStateProv}{" "}
              {entry.DefaultPostalCode}
            </div>
          </React.Fragment>
        ),
    },
    viewName: {
      label: "Name",
      type: "calculated",
      link: true,
      value: (entry) =>
        entry.DisplayName ||
        entry.FirstName +
          (entry.MiddleName
            ? " " + entry.MiddleName.substring(0, 1) + ". "
            : " ") +
          entry.LastName +
          (entry.Suffix ? " " + entry.Suffix : ""),
    },
  },
};

export default class UserService extends Service {
  static getSchema() {
    return schema;
  }
  static getField(fieldName) {
    return schema.fields[fieldName];
  }
}
