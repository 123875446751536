import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/infosharedwiths`,
  createTitle: "Create Info Shared With",
  viewTitle: "Info Shared Withs",
  editTitle: "Edit Info Shared With",
  columns: ["memberid", "sharedwith"],
  fields: {
    memberid: {
      label: "Member",
      type: "unid",
    },
    sharedwith: {
      label: "Shared With",
      type: "unid",
    },
  },
};

export default class InfoSharedWithService extends Service {
  static getSchema() {
    return schema;
  }
}
