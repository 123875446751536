import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";

export default class CallFlow extends Component {
  state = { name: "", phone: "", enabled: "N", unid: "", callflow: [] };

  initCallFlowEntry() {
    return {
      type: "",
      label: "",
      text: "",
      goto: "",
      responses: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((r) => {
        return {
          goto: "",
          text: "",
          phone: "",
        };
      }),
    };
  }

  initCallFlowEntries(callflow) {
    let ret = callflow.map((cf) => {
      return { ...this.initCallFlowEntry(), ...cf };
    });
    return ret;
  }

  async componentDidMount() {
    if (this.props.match.params.id !== "new") {
      let response = await Rest.get(
        "/api/callflows/" + this.props.match.params.id
      );
      if (response.status === 200) {
        let callflow = response.data.callflow;
        callflow = this.initCallFlowEntries(callflow);
        this.setState({
          name: response.data.name,
          phone: response.data.phone,
          enabled: response.data.enabled,
          unid: response.data.unid,
          callflow: callflow,
        });
      }
    }
  }

  handleTextField = ({ currentTarget: input }) => {
    this.setState({ [input.name]: input.value });
  };

  renderRow(cf, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <input
            name={"label_" + index}
            id={"label_" + index}
            value={cf.label}
            onChange={({ currentTarget: input }) => {
              let callflow = [...this.state.callflow];
              callflow[index].label = input.value;
              this.setState({ callflow });
            }}
          />
        </td>
        <td>
          <select
            value={cf.type}
            onChange={({ currentTarget: input }) => {
              let callflow = [...this.state.callflow];
              callflow[index].type = input.value;
              this.setState({ callflow });
            }}
          >
            <option value="">--- select ---</option>
            <option value="speak">Speak</option>
            <option value="pause">Pause 2 seconds</option>
            <option value="goto">Go to</option>
            <option value="hangup">hangup</option>
            <option value="response">Response</option>
            <option value="voicemail">Voicemail</option>
            <option value="dial">Dial</option>
          </select>
        </td>
        <td>
          {(cf.type === "speak" || cf.type === "hangup") && (
            <input
              name={"text_" + index}
              id={"text_" + index}
              value={cf.text}
              onChange={({ currentTarget: input }) => {
                let callflow = [...this.state.callflow];
                callflow[index].text = input.value;
                this.setState({ callflow });
              }}
            />
          )}
          {cf.type === "dial" && (
            <input
              name={"phone_" + index}
              id={"phone_" + index}
              value={cf.phone}
              onChange={({ currentTarget: input }) => {
                let callflow = [...this.state.callflow];
                callflow[index].phone = input.value;
                this.setState({ callflow });
              }}
            />
          )}
          {cf.type === "response" && (
            <React.Fragment>
              <div style={{ height: "30px" }}>
                <input
                  name={"text_" + index}
                  id={"text_" + index}
                  value={cf.text}
                  onChange={({ currentTarget: input }) => {
                    let callflow = [...this.state.callflow];
                    callflow[index].text = input.value;
                    this.setState({ callflow });
                  }}
                />
              </div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((r) => (
                <div style={{ height: "30px" }} key={r}>
                  {String(r) + ": "}
                  <input
                    name={"text_" + index + "_" + r}
                    id={"text_" + index + "_" + r}
                    value={cf.responses[r].text}
                    onChange={({ currentTarget: input }) => {
                      let callflow = [...this.state.callflow];
                      callflow[index].responses[r].text = input.value;
                      this.setState({ callflow });
                    }}
                  />
                </div>
              ))}
            </React.Fragment>
          )}
        </td>
        <td>
          {(cf.type === "goto" ||
            cf.type === "speak" ||
            cf.type === "dial") && (
            <select
              value={cf.goto}
              onChange={({ currentTarget: input }) => {
                let callflow = [...this.state.callflow];
                callflow[index].goto = input.value;
                this.setState({ callflow });
              }}
            >
              <option value="">--- next row ---</option>
              {this.state.callflow.map((cf, indexOptions) => (
                <option
                  key={indexOptions}
                  disabled={indexOptions === index}
                  value={indexOptions}
                >
                  {indexOptions +
                    1 +
                    ": " +
                    this.state.callflow[indexOptions].label}
                </option>
              ))}
            </select>
          )}
          {cf.type === "response" && (
            <React.Fragment>
              <div style={{ height: "30px" }}></div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((r) => (
                <div style={{ height: "30px" }} key={r}>
                  <select
                    value={cf.responses[r].goto}
                    onChange={({ currentTarget: input }) => {
                      let callflow = [...this.state.callflow];
                      callflow[index].responses[r].goto = input.value;
                      this.setState({ callflow });
                    }}
                  >
                    <option value="">--- next row ---</option>
                    {this.state.callflow.map((cf, indexOptions) => (
                      <option
                        disabled={indexOptions === index}
                        value={indexOptions}
                      >
                        {indexOptions +
                          1 +
                          ": " +
                          this.state.callflow[indexOptions].label}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </React.Fragment>
          )}
        </td>
        <td>
          <button
            name="delete"
            onClick={() => {
              let callflow = [...this.state.callflow];
              let error = undefined;
              callflow.forEach((cf, cfindex) => {
                if (cf.type === "response") {
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].forEach((i) => {
                    if (cf.responses[i].goto === String(index)) {
                      error =
                        "Cannot delete - response row " +
                        (cfindex + 1) +
                        " " +
                        cf.label +
                        "[" +
                        i +
                        "] is pointing to this item";
                    } else if (
                      cf.responses[i].goto &&
                      parseInt(cf.responses[i].goto) > index
                    ) {
                      cf.responses[i].goto = String(
                        parseInt(cf.responses[i].goto) - 1
                      );
                    }
                  });
                } else {
                  if (cf.goto === String(index)) {
                    error =
                      "Cannot delete - row " +
                      (cfindex + 1) +
                      " " +
                      cf.label +
                      " is pointing to this item";
                  } else if (cf.goto && parseInt(cf.goto) > index) {
                    cf.goto = String(parseInt(cf.goto) - 1);
                  }
                }
              });
              if (error) {
                alert(error);
              } else {
                callflow.splice(index, 1);
                this.setState({ callflow });
              }
            }}
          >
            Delete
          </button>
          <button
            name="up"
            onClick={() => {
              this.flipCallFlow(index, index - 1);
            }}
            disabled={index === 0}
          >
            Up
          </button>
          <button
            name="down"
            onClick={() => {
              this.flipCallFlow(index, index + 1);
            }}
            disabled={index >= this.state.callflow.length - 1}
          >
            Down
          </button>
        </td>
      </tr>
    );
  }

  flipCallFlow(index1, index2) {
    let callflow = [...this.state.callflow];
    if (
      index1 < 0 ||
      index2 < 0 ||
      index1 + 1 > callflow.length ||
      index2 + 1 > callflow.length
    ) {
      console.log("ERROR - out of range", index1, index2, callflow.length);
    }

    let strIndex1 = String(index1);
    let strIndex2 = String(index2);

    let tmp = callflow[index1];
    callflow[index1] = callflow[index2];
    callflow[index2] = tmp;

    console.log(callflow, index1, index2);

    for (let i = 0; i < callflow.length; i++) {
      if (callflow[i].goto === strIndex1) {
        callflow[i].goto = strIndex2;
      } else if (callflow[i].goto === strIndex2) {
        callflow[i].goto = strIndex1;
      }
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].forEach((r) => {
        if (callflow[i].responses[r].goto === strIndex1) {
          callflow[i].responses[r].goto = strIndex2;
        } else if (callflow[i].responses[r].goto === strIndex2) {
          callflow[i].responses[r].goto = strIndex1;
        }
      });
    }

    this.setState({ callflow });
  }

  renderCallFlow() {
    return (
      <React.Fragment>
        <table border="1">
          <thead>
            <tr>
              <th>#</th>
              <th>Label</th>
              <th>Type</th>
              <th>Text</th>
              <th>Go to</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.callflow.map((cf, index) => this.renderRow(cf, index))}
          </tbody>
        </table>
        <button
          onClick={async (e) => {
            e.preventDefault();
            let callflow = [...this.state.callflow];
            callflow.push(this.initCallFlowEntry());
            this.setState({ callflow });
          }}
        >
          Add Row
        </button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Field
          name="name"
          type="text"
          label="Name"
          value={this.state.name}
          onChange={this.handleTextField}
        />
        <Field
          name="phone"
          type="text"
          label="Phone"
          value={this.state.phone}
          onChange={this.handleTextField}
        />
        <Field
          name="enabled"
          type="select"
          label="Enabled"
          value={this.state.enabled}
          onChange={({ currentTarget: input }) => {
            this.setState({ enabled: input.value });
          }}
          options={[
            { unid: "Y", name: "Yes" },
            { unid: "N", name: "No" },
          ]}
        />
        <br />
        <br />
        {this.renderCallFlow()}
        <br />
        <Form
          history={this.props.history}
          id={this.props.match.params.id}
          form="callflows"
          data={{
            name: this.state.name,
            phone: this.state.phone,
            enabled: this.state.enabled,
            callflow: this.state.callflow,
          }}
        />
      </React.Fragment>
    );
  }
}
