import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Table from "./common/Table";
import FamilyService from "../service/FamilyService";
import UserService from "../service/UserService";

export default class Families extends Component {
  state = { rows: [], nofamilies: [] };

  async componentDidMount() {
    await this.loadFamilies();
  }

  loadFamilies = async () => {
    let state = { rows: [], nofamilies: [] };
    let response = await Rest.get("/api/families");
    if (response.status === 200) {
      let data = [...response.data];
      data.sort((a, b) =>
        a.LastName < b.LastName ? -1 : a.LastName === b.LastName ? 0 : 1
      );
      state.rows = data;
    }
    response = await Rest.get("/api/users");
    if (response.status === 200) {
      state.nofamilies = [...response.data];
      state.nofamilies.sort((a, b) =>
        a.DefaultAddress < b.DefaultAddress
          ? -1
          : a.DefaultAddress === b.DefaultAddress
          ? 0
          : 1
      );
      let unids = {};
      state.rows.forEach((row) => {
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((index) => {
          if (unids[row["member" + index]]) {
            unids[row["member" + index]]++;
            console.log("Duplicate", row["member" + index]);
          } else {
            unids[row["member" + index]] = 1;
          }
        });
      });
      console.log("UNIDS", unids);
      state.nofamilies = state.nofamilies.filter(
        (family) => !unids[family.unid]
      );
    }
    this.setState(state);
  };

  render() {
    return (
      <React.Fragment>
        <Table
          schema={FamilyService.getSchema()}
          rows={this.state.rows}
          history={this.props.history}
        />
        <h3>Individuals without families assigned</h3>
        <Table
          schema={UserService.getSchema()}
          rows={this.state.nofamilies}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}
