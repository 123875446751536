export default class utils {
  static normalizePhoneNumber(phone) {
    phone = phone.trim();
    if (phone.startsWith("+1")) {
      phone = phone.substring("+1".length);
    }
    phone = phone.replace(/\D/g, "");
    return "+1" + phone;
  }

  static formatPhone(phone) {
    let p = phone.trim();
    if (p.startsWith("+1")) {
      p = p.substring("+1".length);
    }
    p = p.replace(/\D/g, "");
    if (p.length === 10) {
      return p.substring(0, 3) + "-" + p.substring(3, 6) + "-" + p.substring(6);
    } else {
      return phone;
    }
  }

  static formatMMDDYYY_HHMM = (iso) => {
    let d = new Date(iso);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    let year = String(d.getFullYear());
    let hours = d.getHours();
    let ampm;

    if (hours === 0) {
      hours = 12;
      ampm = "am";
    } else if (hours === 12) {
      ampm = "pm";
    } else if (hours < 12) {
      ampm = "am";
    } else {
      hours -= 12;
      ampm = "pm";
    }
    hours = String(hours);
    let minute = (d.getMinutes() < 10 ? "0" : "") + String(d.getMinutes());
    return month + "/" + day + "/" + year + " " + hours + ":" + minute + ampm;
  };
}
