import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/groups`,
  createTitle: "Create Group",
  viewTitle: "Groups",
  editTitle: "Edit Group",
  columns: ["name", "subscribable", "type"],
  fields: {
    name: {
      label: "Name",
    },
    subscribable: {
      label: "Subscribable",
    },
    type: {
      label: "Type",
    },
  },
};

export default class GroupService extends Service {
  static getSchema() {
    return schema;
  }
}
