import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/membergroups`,
  createTitle: "Create Member Group",
  viewTitle: "Member Groups",
  editTitle: "Edit Member Group",
  columns: [
    "memberid",
    "groupid",
    "notify_sms",
    "notify_email",
    "notify_email2",
    "notify_voice",
    "isgroupadmin",
    "isgroupsubmitter",
    "isgroupreader",
  ],
  fields: {
    memberid: {
      label: "Member",
      type: "unid",
    },
    groupid: {
      label: "Group",
      type: "unid",
    },
    notify_sms: {
      label: "via SMS",
      type: "boolean",
    },
    notify_email: {
      label: "via e-mail",
      type: "boolean",
    },
    notify_email2: {
      label: "via e-mail2",
      type: "boolean",
    },
    notify_voice: {
      label: "via phone",
      type: "boolean",
    },
    isgroupadmin: {
      label: "Is Group Admin",
      type: "boolean",
    },
    isgroupsubmitter: {
      label: "Is Group Submitter",
      type: "boolean",
    },
    isgroupreader: {
      label: "Is Group Reader",
      type: "boolean",
    },
  },
};

export default class GroupService extends Service {
  static getSchema() {
    return schema;
  }
}
