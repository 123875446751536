import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/messages`,
  createTitle: "Create Message",
  viewTitle: "Messages",
  editTitle: "Message",
  columns: [
    "created",
    "authorid",
    "groupid",
    "message.sms_text",
    "message.call_text",
    "message.email_subject",
  ],
  viewGroups: ["groupid"],
  fields: {
    created: {
      key: "created",
      label: "Created",
      display: "d-none d-sm-table-cell",
      type: "datetime",
    },
    authorid: {
      key: "authorid",
      label: "Author",
      type: "unid",
    },
    groupid: {
      key: "groupid",
      label: "To Group",
      type: "unid",
    },
    "message.sms_text": {
      label: "SMS",
    },
    "message.call_text": {
      label: "Voice",
    },
    "message.email_subject": {
      label: "Subject",
    },
  },
};

export default class MessageService extends Service {
  static getSchema() {
    return schema;
  }
}
