import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Table from "./common/Table";
import MemberGroupService from "../service/MemberGroupService";

export default class MemberGroups extends Component {
  state = { memberGroups: [], unids: {} };
  async componentDidMount() {
    let promiseMemberGroups = Rest.get("/api/membergroups");
    let promiseMembers = Rest.get("/api/users");
    let promiseGroups = Rest.get("/api/groups");
    let response = await Promise.all([
      promiseMemberGroups,
      promiseMembers,
      promiseGroups,
    ]);
    let state = { memberGroups: [], unids: {} };
    if (response[0].status === 200) {
      state.memberGroups = response[0].data;
    }
    if (response[1].status === 200) {
      response[1].data.forEach((member) => {
        state.unids[member.unid] = member.FirstName + " " + member.LastName;
      });
    }
    if (response[2].status === 200) {
      state.groups = {};
      response[2].data.forEach((group) => {
        state.unids[group.unid] = group.name;
      });
    }
    this.setState(state);
  }

  render() {
    return (
      <Table
        schema={MemberGroupService.getSchema()}
        rows={this.state.memberGroups}
        unids={this.state.unids}
        history={this.props.history}
      />
    );
  }
}
