import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Field from "./Field";
import utils from "../../utils/utils";

export default class Table extends Component {
  state = {
    rows: undefined,
    search: "",
    groupOptions: {},
    selectedGroup: {},
    filtersShown: false,
  };

  optionsMap = new Map();

  componentDidUpdate = () => {
    if (!this.state.rows) {
      let rows = [];
      let groupOptions = {};
      this.props.rows.forEach((row) => {
        let r = { ...row };
        this.props.schema.columns.forEach((column) => {
          let value = Table.getHierarchicalValue(row, column);
          let field = this.props.schema.fields[column];
          if (!field) {
            value = "";
          } else if (field.type === "unid" && this.props.unids) {
            value = this.props.unids[value] || value;
          } else if (field.type === "datetime") {
            value = utils.formatMMDDYYY_HHMM(value);
          } else if (field.type === "phone") {
            value = utils.formatPhone(value);
          } else if (field.type === "boolean") {
            value = value ? "yes" : "no";
          } else if (field.type === "calculated") {
            value = field.value(row);
          } else if (field.type === "select" && field.options) {
            let v = this.optionsMap.get(field.name + "_" + value);
            if (!v) {
              v = field.options.find((f) => f.unid === value);
              v = v && v.name;
              this.optionsMap.set(field.name + "_" + value, v);
            }
            if (v) {
              value = v;
            }
          }
          r[column] = value;
          if (
            this.props.schema.viewGroups &&
            this.props.schema.viewGroups.includes(column)
          ) {
            if (!(column in groupOptions)) {
              groupOptions[column] = {};
            }
            if (Array.isArray(value)) {
              value.forEach((v) => {
                groupOptions[column][v] = { unid: v, name: v };
              });
            } else {
              groupOptions[column][value] = { unid: value, name: value };
            }
          }
        });
        rows.push(r);
      });
      let selectedGroup = {};
      Object.keys(groupOptions).forEach((column) => {
        groupOptions[column] = Object.values(groupOptions[column]).sort(
          (o1, o2) =>
            o1.name === null
              ? -1
              : o2.name === null
              ? 1
              : o1.name.toLowerCase() < o2.name.toLowerCase()
              ? -1
              : o1.name.toLowerCase() > o2.name.toLowerCase()
              ? 1
              : 0
        );
        selectedGroup[column] = "";
      });
      this.setState({ rows, groupOptions, selectedGroup });
    }
  };

  static getHierarchicalValue = (row, key) => {
    let data = { ...row };
    let keys = key.split(".");
    keys.forEach((k) => {
      data = data[k];
    });
    return data;
  };

  render() {
    if (!this.state.rows) {
      return null;
    }
    let needle = this.state.search.toLowerCase().split(" ");
    return (
      <React.Fragment>
        {this.props.schema.baseUrl && this.props.schema.createTitle && (
          <button
            type="button"
            className="btn btn-primary m-2 d-none d-lg-block"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push(this.props.schema.baseUrl + "/new");
            }}
          >
            {this.props.schema.createTitle || "Create"}
          </button>
        )}
        <div className="form-group my-0">
          {Object.keys(this.state.groupOptions).length > 0 && (
            <button
              onClick={() => {
                this.setState({ filtersShown: !this.state.filtersShown });
              }}
              className="my-3"
            >
              <div style={{ width: "60px" }}>
                Filters&nbsp;
                <span
                  className={
                    this.state.filtersShown
                      ? "fa fa-sort-asc"
                      : "fa fa-sort-desc"
                  }
                />
              </div>
            </button>
          )}
          <input
            type="text"
            name="search"
            className="form-control my-3"
            placeholder="Search..."
            value={this.state.search}
            onChange={({ currentTarget: input }) => {
              this.setState({ search: input.value });
            }}
          />
        </div>
        {!this.state.filtersShown &&
          this.state.selectedGroup &&
          Object.keys(this.state.selectedGroup).map((column) => {
            if (this.state.selectedGroup[column]) {
              return (
                <div
                  key={column}
                  className="mb-3"
                  style={{ backgroundColor: "#EEEEEE" }}
                >
                  Filter {this.props.schema.fields[column].label}{" "}
                  {this.state.selectedGroup[column]}
                </div>
              );
            } else {
              return null;
            }
          })}
        {this.state.filtersShown && (
          <div className="mb-3" style={{ backgroundColor: "#EEEEEE" }}>
            {Object.keys(this.state.selectedGroup).map((column) => {
              return (
                <Field
                  key={column}
                  className="m-2"
                  name="group"
                  label={"Filter " + this.props.schema.fields[column].label}
                  type="select"
                  value={this.state.selectedGroup[column]}
                  options={this.state.groupOptions[column]}
                  onChange={({ currentTarget: input }) => {
                    let selectedGroup = { ...this.state.selectedGroup };
                    selectedGroup[column] = input.value;
                    this.setState({ selectedGroup });
                    console.log("setState", selectedGroup);
                  }}
                />
              );
            })}
          </div>
        )}

        <table className="table">
          <thead>
            <tr>
              {this.props.schema.columns.map((column) => {
                let field = this.props.schema.fields[column];
                let classname =
                  field && field.clickable !== false ? "clickable" : "";
                if (field && field.display) {
                  classname += field.display;
                }
                return (
                  <th key={column} className={classname}>
                    {field && field.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.rows.map((row) => {
              let haystack = "";
              Object.keys(row).forEach((key) => {
                if (typeof row[key] === "string") {
                  haystack += " " + row[key].toLowerCase();
                }
              });
              let found = true;
              needle.forEach((n) => {
                if (haystack.indexOf(n) === -1) {
                  found = false;
                }
              });
              Object.keys(this.state.groupOptions).forEach((column) => {
                if (
                  this.state.selectedGroup[column] &&
                  this.state.selectedGroup[column] !== row[column]
                ) {
                  if (Array.isArray(row[column])) {
                    if (
                      !row[column].includes(this.state.selectedGroup[column])
                    ) {
                      found = false;
                    }
                  } else {
                    if (row[column] !== this.state.selectedGroup[column]) {
                      found = false;
                    }
                  }
                }
              });

              if (!found) {
                return null;
              }

              return (
                <tr key={row.unid}>
                  {this.props.schema.columns.map((column, index) => {
                    let value = Table.getHierarchicalValue(row, column);
                    let field = this.props.schema.fields[column];
                    if (
                      (index === 0 && field.link !== false) ||
                      field.link === true
                    ) {
                      return (
                        <td key={column}>
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              this.props.history.push(
                                this.props.schema.baseUrl + "/" + row.unid
                              );
                            }}
                          >
                            {value}
                          </button>
                        </td>
                      );
                    } else {
                      if (Array.isArray(value)) {
                        value = value.filter(
                          (v, index) => value.indexOf(v) === index
                        );
                        value = value.map((c) => <div key={c}>{c}</div>);
                      }
                      return <td key={column}>{value}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}
