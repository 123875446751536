import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import CallFlowService from "../service/CallFlowService";
import Table from "./common/Table";

export default class CallFlows extends Component {
  state = { rows: [] };
  async componentDidMount() {
    let response = await Rest.get("/api/callflows");
    if (response.status === 200) {
      this.setState({ rows: response.data });
    }
  }

  render() {
    return (
      <Table
        schema={CallFlowService.getSchema()}
        rows={this.state.rows}
        history={this.props.history}
      />
    );
  }
}
