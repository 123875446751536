import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";

export default class Message extends Component {
  state = {
    authorid: "",
    memberid: "",
    status: "pending",
    unids: {},
    members: [],
  };

  async componentDidMount() {
    let response = await Rest.get("/api/users");
    if (response.status === 200) {
      let unids = {};
      let members = [];
      response.data.forEach((member) => {
        unids[member.unid] = member.FirstName + " " + member.LastName;
        members.push({
          unid: member.unid,
          name: member.FirstName + " " + member.LastName,
        });
      });
      members.sort((a, b) =>
        a.name < b.name ? -1 : a.name === b.name ? 0 : 1
      );
      this.setState({ unids, members: members });
    }

    let id = this.props.match.params.id;
    if (id !== "new") {
      let response = await Rest.get("/api/contactexchanges/" + id);
      this.setState({ ...response.data });
    } else {
      this.setState({ authorid: Rest.getUserUnid() });
    }
  }

  render() {
    let id = this.props.match.params.id;
    let readOnly = id !== "new";

    return (
      <div className="m-2">
        <Field
          label="From"
          readonly={true}
          value={this.state.unids[this.state.authorid]}
          name="authorid"
        />
        <Field
          type="select"
          label="Exchange with"
          readonly={readOnly}
          value={this.state.memberid}
          name="memberid"
          onChange={({ currentTarget: input }) => {
            this.setState({ memberid: input.value });
          }}
          options={this.state.members}
        />
        <Field
          type="text"
          label="Status"
          readonly={true}
          value={!readOnly ? "pending" : this.state.status}
          name="status"
        />
        <button
          className="btn btn-primary mr-2"
          disabled={readOnly}
          onClick={async (e) => {
            e.preventDefault();
            let data = {
              authorid: this.state.authorid,
              memberid: this.state.memberid,
              status: "pending",
            };

            let response = await Rest.post(
              "/api/contactexchanges" + (id === "new" ? "" : "/" + id),
              data
            );
            if (response.status === 200) {
              this.props.history.push(
                `${process.env.PUBLIC_URL}/contactexchanges`
              );
            }
          }}
        >
          Send
        </button>
        <button
          className="btn btn-secondary mr-2"
          onClick={async (e) => {
            e.preventDefault();
            this.props.history.push(
              `${process.env.PUBLIC_URL}/contactexchanges`
            );
          }}
        >
          Cancel
        </button>
      </div>
    );
  }
}
