import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";
import FamilyService from "../service/FamilyService";
import ProfileImage from "./common/ProfileImage";

const groupStyle = {
  borderBottom: "1px dotted #000",
  fontWeight: "bold",
  width: "100%",
  display: "block",
};

export default class Family extends Component {
  state = {
    editMode: this.props.match.params.id === "new",
    family: {
      name: "",
      member1: "",
      member2: "",
      member3: "",
      member4: "",
      member5: "",
      member6: "",
      member7: "",
      member8: "",
      member9: "",
      member10: "",
    },
    users: [],
    unids: {},
  };

  async componentDidMount() {
    let state = {};
    if (this.props.match.params.id !== "new") {
      let response = await Rest.get(
        "/api/families/" + this.props.match.params.id
      );
      state.family = response.data;
    }
    let response = await Rest.get("/api/users");
    state.users = response.data.map((user) => ({
      name: user.FirstName + " " + user.LastName,
      ...user,
    }));
    state.unids = {};
    state.users.forEach((user) => {
      state.unids[user.unid] = { ...user };
    });
    this.setState(state);
  }

  handleChange = ({ currentTarget: input }) => {
    let family = { ...this.state.family };
    family[input.name] = input.value;
    this.setState({ family });
  };

  renderContent = () => {
    let family = this.state.family;
    return (
      <div>
        {this.state.editMode ? (
          <React.Fragment>
            <Field
              label="Family Name"
              name="name"
              type="text"
              value={this.state.family.name}
              onChange={this.handleChange}
            />
            <span style={{ ...groupStyle, fontSize: "2em" }}>Members</span>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
              <Field
                name={"member" + index}
                key={"member" + index}
                type="select"
                value={family["member" + index]}
                onChange={this.handleChange}
                options={this.state.users}
                sorted={true}
              />
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span style={{ ...groupStyle, fontSize: "2em" }}>
              {family.name} Family
            </span>
            <ProfileImage
              memberid={family.unid}
              readonly={!Rest.hasAccessGroup("user_write_all")}
              photoid={family.photoid}
              name={family.name}
              isfamily={true}
            />
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
              let member = family["member" + index];
              if (member) {
                member = this.state.unids[member];
                let name = member.FirstName + " " + member.LastName;
                return (
                  <div>
                    <button
                      style={{ width: "100%" }}
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `${process.env.PUBLIC_URL}/users/` + member.unid
                        );
                      }}
                    >
                      <ProfileImage
                        memberid={member.unid}
                        photoid={member.photoid}
                        readonly={true}
                        name={name}
                        size={50}
                      />
                      {name}
                    </button>
                  </div>
                );
              }
              return null;
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderButtons = () => {
    if (!this.state.editMode) {
      return (
        <React.Fragment>
          <button
            className="btn btn-secondary mr-2"
            onClick={(e) => {
              this.setState({ editMode: true });
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-secondary mr-2"
            onClick={(e) => {
              this.props.history.push(`${process.env.PUBLIC_URL}/families`);
            }}
          >
            Close
          </button>
        </React.Fragment>
      );
    }

    let family = {};
    let fields = FamilyService.getSchema().fields;
    Object.keys(fields).forEach((fieldName) => {
      let field = fields[fieldName];
      if (field.type !== "calculated") {
        family[fieldName] = this.state.family[fieldName];
      }
    });

    return (
      <Form
        history={this.props.history}
        id={this.props.match.params.id}
        form="families"
        data={family}
      />
    );
  };

  render() {
    if (
      !Rest.hasAccessGroup("user_read_partial,user_read_all,user_write_all") &&
      Rest.getUserUnid() !== this.props.match.params.id
    ) {
      this.props.history.push(`${process.env.PUBLIC_URL}/`);
      return null;
    }
    return (
      <React.Fragment>
        {this.renderContent()}
        {this.renderButtons()}
      </React.Fragment>
    );
  }
}
