import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../../utils/rest";

export default class Form extends Component {
  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-primary mr-2"
          onClick={async (e) => {
            e.preventDefault();
            let response = await Rest.post(
              "/api/" +
                this.props.form +
                (this.props.id === "new" ? "" : "/" + this.props.id),
              this.props.data
            );
            if (response.status === 200) {
              this.props.history.push(
                `${process.env.PUBLIC_URL}/` + this.props.form
              );
            }
          }}
        >
          {this.props.id === "new" ? "Create" : "Update"}
        </button>
        <button
          className="btn btn-secondary mr-2"
          onClick={async (e) => {
            e.preventDefault();
            this.props.history.push(
              `${process.env.PUBLIC_URL}/` + this.props.form
            );
          }}
        >
          Cancel
        </button>
        {this.props.id !== "new" && (
          <button
            className="btn btn-danger"
            onClick={async (e) => {
              e.preventDefault();
              let response = await Rest.delete(
                "/api/" + this.props.form + "/" + this.props.id
              );
              if (response.status === 200) {
                this.props.history.push(
                  `${process.env.PUBLIC_URL}/` + this.props.form
                );
              }
            }}
          >
            Delete
          </button>
        )}
      </React.Fragment>
    );
  }
}
