import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Table from "./common/Table";
import UserService from "../service/UserService";

export default class Users extends Component {
  state = { rows: [], schema: UserService.getSchema() };

  async componentDidMount() {
    await this.loadUsers();
  }

  loadUsers = async () => {
    let response = await Promise.all([
      Rest.get("/api/users"),
      Rest.get("/api/membergroups"),
      Rest.get("/api/groups"),
    ]);
    if (
      response[0].status === 200 &&
      response[1].status === 200 &&
      response[2].status === 200
    ) {
      let users = response[0].data;
      let memberGroups = response[1].data;
      let groups = response[2].data;

      let byMemberId = {};
      memberGroups.forEach((mg) => {
        byMemberId[mg.memberid] = [...(byMemberId[mg.memberid] || []), mg];
      });

      let byGroups = {};
      groups.forEach((g) => {
        byGroups[g.unid] = g;
      });

      users.sort((a, b) =>
        a.LastName < b.LastName ? -1 : a.LastName === b.LastName ? 0 : 1
      );
      for (let i = 0; i < users.length; i++) {
        let groups = [];
        if (byMemberId[users[i].unid]) {
          byMemberId[users[i].unid].forEach((mg) => {
            groups = [...groups, byGroups[mg.groupid].name];
          });
        }
        users[i]["MemberGroups"] = groups;
      }

      let schema = { ...UserService.getSchema() };
      schema.columns = [...schema.columns, "MemberGroups"];
      schema.viewGroups = [...schema.viewGroups, "MemberGroups"];
      schema.fields = {
        ...schema.fields,
        MemberGroups: {
          label: "Groups",
        },
      };

      this.setState({ rows: users, schema });
    }
  };

  render() {
    return (
      <Table
        schema={this.state.schema}
        rows={this.state.rows}
        history={this.props.history}
      />
    );
  }
}
