import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";

export default class InfoSharedWith extends Component {
  state = { infoSharedWith: {}, members: [] };

  async componentDidMount() {
    let id = this.props.match.params.id;
    let promiseInfoSharedWith =
      id === "new"
        ? Promise.resolve({
            status: 200,
            data: {
              selectedMember: "",
              selectedGroup: "",
              access: "",
            },
          })
        : Rest.get("/api/infosharedwiths/" + this.props.match.params.id);
    let promiseMembers = Rest.get("/api/users");
    let response = await Promise.all([promiseInfoSharedWith, promiseMembers]);
    let state = {};
    if (response[0].status === 200) {
      state.infoSharedWith = response[0].data;
    }
    if (response[1].status === 200) {
      state.members = response[1].data.map((member) => ({
        unid: member.unid,
        name: member.FirstName + " " + member.LastName,
      }));
    }
    this.setState(state);
  }

  render() {
    return (
      <div className="m-2">
        <Field
          label="Member"
          name="memberid"
          type="select"
          sorted={true}
          value={this.state.infoSharedWith.memberid}
          onChange={({ currentTarget: input }) => {
            let infoSharedWith = { ...this.state.infoSharedWith };
            infoSharedWith.memberid = input.value;
            this.setState({ infoSharedWith });
          }}
          options={this.state.members}
        />
        <Field
          label="Shared With"
          name="sharedwith"
          type="select"
          sorted={true}
          value={this.state.infoSharedWith.sharedwith}
          onChange={({ currentTarget: input }) => {
            let infoSharedWith = { ...this.state.infoSharedWith };
            infoSharedWith.sharedwith = input.value;
            this.setState({ infoSharedWith });
          }}
          options={this.state.members}
        />
        <Form
          history={this.props.history}
          id={this.props.match.params.id}
          form="infosharedwiths"
          data={this.state.infoSharedWith}
        />
      </div>
    );
  }
}
