import Service from "./Service";
import React from "react";
import ProfileImage from "../components/common/ProfileImage";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/families`,
  createTitle: "Create Family",
  viewTitle: "Families",
  editTitle: "Edit Family",
  columns: ["viewPhoto", "name"],
  fields: {
    name: {
      label: "Family Name",
      link: true,
      display: "d-none d-sm-table-cell",
    },
    member1: {
      label: "Member 1",
    },
    member2: {
      label: "Member 1",
    },
    member3: {
      label: "Member 1",
    },
    member4: {
      label: "Member 1",
    },
    member5: {
      label: "Member 1",
    },
    member6: {
      label: "Member 1",
    },
    member7: {
      label: "Member 1",
    },
    member8: {
      label: "Member 1",
    },
    member9: {
      label: "Member 1",
    },
    member10: {
      label: "Member 1",
    },
    photo: {
      label: "Photo",
    },
    viewPhoto: {
      label: "",
      type: "calculated",
      link: false,
      value: (entry) => (
        <ProfileImage
          readonly={true}
          size={50}
          memberid={entry.unid}
          photoid={entry.photoid}
          name={entry.name}
          isfamily={true}
        />
      ),
    },
  },
};

export default class FamilyService extends Service {
  static getSchema() {
    return schema;
  }
  static getField(fieldName) {
    return schema.fields[fieldName];
  }
}
