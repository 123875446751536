import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

export default class Table extends Component {
  renderInput() {
    return this.props.readonly ? (
      <span>{this.props.value}</span>
    ) : (
      <React.Fragment>
        <span className="d-none d-print-block">{this.props.value}</span>
        <input
          placeholder={this.props.placeholder}
          style={this.props.style || { width: "100%" }}
          type={this.props.type || "text"}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }

  renderTextArea() {
    return this.props.readonly ? (
      <span
        style={{ whiteSpace: "pre-wrap", fontFamily: "courier, monospace" }}
      >
        {this.props.value}
      </span>
    ) : (
      <React.Fragment>
        <span className="d-none d-print-block">{this.props.value}</span>
        <textarea
          name={this.props.name}
          id={this.props.name}
          style={this.props.style || { width: "100%" }}
          value={this.props.value}
          onChange={this.props.onChange}
          rows="3"
        />
      </React.Fragment>
    );
  }

  renderSelect() {
    let options = [...this.props.options];
    if (this.props.sorted) {
      options.sort((a, b) =>
        a.name < b.name ? -1 : a.name === b.name ? 0 : 1
      );
    }
    if (this.props.readonly && options.length > 0) {
      let optionsValue = options.find((o) => o.unid === this.props.value);
      return <span>{optionsValue && optionsValue.name}</span>;
    } else {
      return (
        <select
          style={this.props.style || { width: "100%" }}
          value={this.props.value}
          name={this.props.name}
          onChange={this.props.onChange}
        >
          <option value="">--- Select ---</option>
          {options.map((option) => (
            <option
              value={option.unid}
              key={option.unid}
              disabled={option.disabled || false}
            >
              {option.name}
            </option>
          ))}
        </select>
      );
    }
  }

  renderCheckbox() {
    return this.props.value.map((item) => (
      <div style={{ padding: "5px", marginRight: "1em" }} key={item.unid}>
        <input
          name={this.props.name + "_" + item.unid}
          type="checkbox"
          disabled={this.props.readonly}
          checked={item.value}
          onChange={({ currentTarget: input }) => {
            let newValue = [...this.props.value];
            for (let i = 0; i < newValue.length; i++) {
              if (input.name === this.props.name + "_" + newValue[i].unid) {
                newValue[i] = { ...newValue[i] };
                newValue[i].value = !newValue[i].value;
              }
            }
            this.props.onChange(newValue);
          }}
        />{" "}
        {item.name}
      </div>
    ));
  }

  renderField() {
    return this.props.type === "select"
      ? this.renderSelect()
      : this.props.type === "textarea"
      ? this.renderTextArea()
      : this.props.type === "checkbox"
      ? this.renderCheckbox()
      : this.renderInput();
  }

  render() {
    let ret = undefined;
    if (this.props.label === undefined) {
      if (this.props.error && this.props.error[this.props.name]) {
        ret = (
          <React.Fragment>
            <div className="form-group">{this.renderField}</div>
            <div className="alert alert-danger">
              {this.props.error[this.props.name]}
            </div>
          </React.Fragment>
        );
      } else {
        ret = this.renderField();
      }
    } else {
      ret = (
        <React.Fragment>
          <div className="form-group" key={this.props.name}>
            <label htmlFor={this.props.name}>{this.props.label}</label>
            {this.renderField()}
          </div>
          {this.props.error && this.props.error[this.props.name] && (
            <div className="alert alert-danger">
              {this.props.error[this.props.name]}
            </div>
          )}
        </React.Fragment>
      );
    }
    if (this.props.className) {
      return <div className={this.props.className}>{ret}</div>;
    } else {
      return ret;
    }
  }
}

Table.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};
