/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import Rest from "./rest";

async function login(email, password) {
  try {
    let res = await Rest.post("/api/users/login", {
      email: email,
      password: password,
    });
    console.log("RES", res);
    if (res.data && res.data.token && res.headers["x-jwt-mvsda-org"]) {
      console.log("STEP1");
      Rest.setProfileEncoded(res.headers["x-jwt-mvsda-org"]);
    } else if (res.data && res.data.error) {
      console.log("STEP2");
      return res.data.error;
    } else {
      console.log("STEP3");
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function isValidEmail(email) {
  try {
    let res = await Rest.post("/api/users", {
      checkemail: email,
    });
    if (res.data && res.data.error) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
  return false;
}

async function loginSession(sessionid) {
  try {
    let res = await Rest.post("/api/users", {
      sessionid: sessionid,
    });
    if (res.data && res.data.token) {
      Rest.setProfileEncoded(res.data.token);
      if (res.data.redirect) {
        window.location.href = res.data.redirect;
      }
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function changePassword(password, sessionid) {
  try {
    let res = await Rest.post("/api/users", {
      sessionid: sessionid,
      password: password,
    });
    if (res.data && res.data.token) {
      Rest.setProfileEncoded(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function resetPassword(email) {
  try {
    let res = await Rest.post("/api/users", {
      resetemail: email,
    });
    if (res.data && res.data.error) {
      return res.data.error;
    } else if (res.data && res.data.msg) {
      return res.data.msg;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

const exportDefault = {
  isValidEmail,
  login,
  loginSession,
  changePassword,
  resetPassword,
};

export default exportDefault;
