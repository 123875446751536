import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/callflows`,
  createTitle: "Create Call Flow",
  viewTitle: "Call Flows",
  editTitle: "Edit Call Flow",
  columns: ["name", "phone", "enabled"],
  fields: {
    name: {
      label: "Name",
    },
    phone: {
      label: "Phone",
    },
    enabled: {
      label: "Enabled",
    },
  },
};

export default class CallFlowService extends Service {
  static getSchema() {
    return schema;
  }
}
