import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/contactexchanges`,
  createTitle: "Create Contact Exchange",
  viewTitle: "Contact Exchanges",
  editTitle: "Contact Exchange",
  columns: ["created", "authorid", "memberid", "status"],
  viewGroups: ["groupid"],
  fields: {
    created: {
      key: "created",
      label: "Created",
      display: "d-none d-sm-table-cell",
      type: "datetime",
    },
    authorid: {
      key: "authorid",
      label: "Author",
      type: "unid",
    },
    memberid: {
      key: "memberid",
      label: "Exchange with",
      type: "unid",
    },
    status: {
      key: "status",
      label: "Status",
      type: "select",
      options: [
        { unid: "pending", name: "Pending" },
        { unid: "approved", name: "Approved" },
        { unid: "rejected", name: "Rejected" },
      ],
    },
  },
};

export default class ContactExchangeService extends Service {
  static getSchema() {
    return schema;
  }
}
