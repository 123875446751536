import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Form from "./common/Form";

export default class MemberGroup extends Component {
  state = { memberGroup: {}, members: [], groups: [] };

  async componentDidMount() {
    let id = this.props.match.params.id;
    let promiseMemberGroups =
      id === "new"
        ? Promise.resolve({
            status: 200,
            data: {
              selectedMember: "",
              selectedGroup: "",
              access: "",
            },
          })
        : Rest.get("/api/membergroups/" + this.props.match.params.id);
    let promiseMembers = Rest.get("/api/users");
    let promiseGroups = Rest.get("/api/groups");
    let response = await Promise.all([
      promiseMemberGroups,
      promiseMembers,
      promiseGroups,
    ]);
    let state = {};
    if (response[0].status === 200) {
      state.memberGroup = response[0].data;
    }
    if (response[1].status === 200) {
      state.members = response[1].data;
    }
    if (response[2].status === 200) {
      state.groups = response[2].data;
    }
    this.setState(state);
  }

  renderCheckbox(name) {
    return (
      <input
        name={name}
        type="checkbox"
        id={name}
        checked={this.state.memberGroup[name] ? true : false}
        onChange={({ currentTarget: input }) => {
          let memberGroup = { ...this.state.memberGroup };
          memberGroup[name] = !memberGroup[name];
          this.setState({ memberGroup });
        }}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        Member:
        <select
          name="memberid"
          value={this.state.memberGroup.memberid}
          onChange={({ currentTarget: input }) => {
            let memberGroup = { ...this.state.memberGroup };
            memberGroup.memberid = input.value;
            this.setState({ memberGroup });
          }}
        >
          <option value="">--- Select ---</option>
          {this.state.members.map((member) => (
            <option value={member.unid} key={member.unid}>
              {member.FirstName + " " + member.LastName}
            </option>
          ))}
        </select>
        <br />
        Group:
        <select
          name="groupid"
          value={this.state.memberGroup.groupid}
          onChange={({ currentTarget: input }) => {
            let memberGroup = { ...this.state.memberGroup };
            memberGroup.groupid = input.value;
            this.setState({ memberGroup });
          }}
        >
          <option value="">--- Select ---</option>
          {this.state.groups.map((group) => (
            <option value={group.unid} key={group.unid}>
              {group.name}
            </option>
          ))}
        </select>
        <br />
        {this.renderCheckbox("notify_sms")} Notify SMS
        <br />
        {this.renderCheckbox("notify_email")} Notify e-mail
        <br />
        {this.renderCheckbox("notify_email2")} Notify e-mail2
        <br />
        {this.renderCheckbox("notify_voice")} Notify Voice
        <br />
        {this.renderCheckbox("isgroupadmin")} is group admin
        <br />
        {this.renderCheckbox("isgroupsubmitter")} is group submitter
        <br />
        {this.renderCheckbox("isgroupreader")} is group reader
        <br />
        <Form
          id={this.props.match.params.id}
          form="membergroups"
          data={this.state.memberGroup}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}
