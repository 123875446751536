import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";

export default class Group extends Component {
  state = {
    name: "",
    subscribable: "N",
    type: "",
    unid: "",
    unids: {},
    users: [],
    memberGroups: [],
    newMemberId: "",
  };

  async componentDidMount() {
    if (this.props.match.params.id !== "new") {
      let promiseGroup = Rest.get("/api/groups/" + this.props.match.params.id);
      let promiseMemberGroups = Rest.get("/api/membergroups");
      let promiseUsers = Rest.get("/api/users");
      let response = await Promise.all([
        promiseGroup,
        promiseMemberGroups,
        promiseUsers,
      ]);
      if (response[0].status === 200) {
        this.setState({
          name: response[0].data.name,
          subscribable: response[0].data.subscribable,
          type: response[0].data.type,
          unid: response[0].data.unid,
        });
      }
      let memberGroups = [];
      if (response[1].status === 200) {
        memberGroups = response[1].data.filter(
          (mg) => mg.groupid === this.props.match.params.id
        );
        this.setState({ memberGroups });
      }
      if (response[2].status === 200) {
        let unids = {};
        let users = [];
        response[2].data.forEach((r) => {
          unids[r.unid] = r.FirstName + " " + r.LastName;
          let disabled = false;
          if (memberGroups.find((mg) => mg.memberid === r.unid)) {
            disabled = true;
          }
          users.push({
            unid: r.unid,
            name: r.FirstName + " " + r.LastName,
            disabled,
          });
        });
        this.setState({ unids, users });
      }
    }
  }

  handleTextField = ({ currentTarget: input }) => {
    this.setState({ [input.name]: input.value });
  };

  handleGroupField = ({ currentTarget: input }) => {
    let newText = "";
    input.value
      .toLowerCase()
      .split("")
      .forEach((ch) => {
        if ("1234567890-abcdefghijklmnopqrstuvwxyz._-".indexOf(ch) !== -1) {
          newText += ch;
        }
      });
    this.setState({ [input.name]: newText });
  };

  render() {
    let id = this.props.match.params.id;
    return (
      <div className="m-2">
        <Field
          name="name"
          label="Name"
          id="name"
          value={this.state.name}
          onChange={this.handleGroupField}
        />
        <Field
          name="subscribable"
          type="select"
          label="Subscribable"
          value={this.state.subscribable}
          options={[
            { unid: "Y", name: "Yes" },
            { unid: "N", name: "No" },
          ]}
          onChange={({ currentTarget: input }) => {
            this.setState({ subscribable: input.value });
          }}
        />
        <Field
          name="type"
          type="select"
          label="type"
          value={this.state.type}
          options={[
            { unid: "", name: "" },
            { unid: "mailinglist", name: "Mailing List" },
            { unid: "acl", name: "Access Control" },
          ]}
          onChange={({ currentTarget: input }) => {
            this.setState({ type: input.value });
          }}
        />
        <Form
          history={this.props.history}
          id={this.props.match.params.id}
          form="groups"
          data={{
            name: this.state.name,
            subscribable: this.state.subscribable,
            type: this.state.type,
          }}
        />
        {id !== "new" && this.renderMembers()}
      </div>
    );
  }

  renderCheckbox(name, index) {
    return (
      <input
        type="checkbox"
        name={name}
        checked={this.state.memberGroups[index][name]}
        onChange={async () => {
          let memberGroups = [...this.state.memberGroups];
          memberGroups[index][name] = !memberGroups[index][name];
          this.setState({ memberGroups });
          await Rest.post(
            "/api/membergroups/" + memberGroups[index].unid,
            memberGroups[index]
          );
        }}
      />
    );
  }

  renderMembers = () => {
    return (
      <React.Fragment>
        <hr />
        <h3>Members</h3>
        <table border="1">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th colSpan="3">Notify by</th>
              <th colSpan="3">Permissions</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <th>Name</th>
              <th>SMS</th>
              <th>E-mail</th>
              <th>E-mail2</th>
              <th>Call</th>
              <th>Reader</th>
              <th>Submitter</th>
              <th>Admin</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.memberGroups &&
              this.state.memberGroups.map((mg, index) => (
                <tr key={mg.unid}>
                  <td>{this.state.unids[mg.memberid] || mg.memberid}</td>
                  <td>{this.renderCheckbox("notify_sms", index)}</td>
                  <td>{this.renderCheckbox("notify_email", index)}</td>
                  <td>{this.renderCheckbox("notify_email2", index)}</td>
                  <td>{this.renderCheckbox("notify_voice", index)}</td>
                  <td>{this.renderCheckbox("isgroupreader", index)}</td>
                  <td>{this.renderCheckbox("isgroupsubmitter", index)}</td>
                  <td>{this.renderCheckbox("isgroupadmin", index)}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        Rest.delete("/api/membergroups/" + mg.unid);
                        let memberGroups = this.state.memberGroups.filter(
                          (m) => m.unid !== mg.unid
                        );
                        let users = [...this.state.users];
                        for (let i = 0; i < users.length; i++) {
                          if (users[i].unid === mg.memberid) {
                            users[i].disabled = false;
                          }
                        }
                        this.setState({ memberGroups, users });
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <hr />
        <div>
          Add to group:
          <Field
            style={{ width: "*" }}
            name="newMemberId"
            type="select"
            sorted={true}
            options={this.state.users}
            value={this.state.newMemberId}
            onChange={({ currentTarget: input }) => {
              this.setState({ newMemberId: input.value });
            }}
          />
          &nbsp;
          <button
            className="btn btn-secondary"
            onClick={async () => {
              let mg = {
                memberid: this.state.newMemberId,
                groupid: this.props.match.params.id,
                notify_sms: false,
                notify_email: false,
                notify_email2: false,
                notify_voice: false,
                isgroupreader: false,
                isgroupsubmitter: false,
                isgroupadmin: false,
              };
              mg = (await Rest.post("/api/membergroups", mg)).data;
              let memberGroups = [...this.state.memberGroups];
              let unids = { ...this.state.unids };
              memberGroups.push(mg);
              unids[this.state.newMemberId] = this.state.users.find(
                (u) => u.unid === this.state.newMemberId
              ).name;
              let users = [...this.state.users];
              for (let i = 0; i < users.length; i++) {
                if (users[i].unid === this.state.newMemberId) {
                  users[i].disabled = true;
                  break;
                }
              }
              this.setState({ memberGroups, unids, users, newMemberId: "" });
            }}
          >
            Add
          </button>
        </div>
      </React.Fragment>
    );
  };
}
