import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";

export default class MemberAccess extends Component {
  state = { memberAccess: {}, members: [] };

  async componentDidMount() {
    let id = this.props.match.params.id;
    let promiseMemberAccess =
      id === "new"
        ? Promise.resolve({
            status: 200,
            data: {
              selectedMember: "",
              selectedGroup: "",
              access: "",
            },
          })
        : Rest.get("/api/memberaccesses/" + this.props.match.params.id);
    let promiseMembers = Rest.get("/api/users");
    let response = await Promise.all([promiseMemberAccess, promiseMembers]);
    let state = {};
    if (response[0].status === 200) {
      state.memberAccess = response[0].data;
    }
    if (response[1].status === 200) {
      state.members = response[1].data.map((member) => ({
        unid: member.unid,
        name: member.FirstName + " " + member.LastName,
      }));
    }
    this.setState(state);
  }

  render() {
    return (
      <div className="m-2">
        <Field
          label="Member"
          name="memberid"
          type="select"
          sorted={true}
          value={this.state.memberAccess.memberid}
          onChange={({ currentTarget: input }) => {
            let memberAccess = { ...this.state.memberAccess };
            memberAccess.memberid = input.value;
            this.setState({ memberAccess });
          }}
          options={this.state.members}
        />
        <Field
          label="Access"
          name="name"
          type="select"
          value={this.state.memberAccess.name}
          onChange={({ currentTarget: input }) => {
            let memberAccess = { ...this.state.memberAccess };
            memberAccess.name = input.value;
            this.setState({ memberAccess });
          }}
          options={Rest.ACCESS_TYPES}
        />
        <Form
          history={this.props.history}
          id={this.props.match.params.id}
          form="memberaccesses"
          data={this.state.memberAccess}
        />
      </div>
    );
  }
}
