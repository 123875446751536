import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/memberaccesses`,
  createTitle: "Create Member Access",
  viewTitle: "Member Accesses",
  editTitle: "Edit Member Access",
  columns: ["memberid", "name"],
  fields: {
    memberid: {
      label: "Member",
      type: "unid",
    },
    name: {
      label: "Name",
    },
  },
};

export default class MemberAccessService extends Service {
  static getSchema() {
    return schema;
  }
}
