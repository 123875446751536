import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Table from "./common/Table";
import InfoSharedWithService from "../service/InfoSharedWithService";

export default class InfoSharedWiths extends Component {
  state = { rows: [], unids: {} };
  async componentDidMount() {
    let promiseInfoSharedWiths = Rest.get("/api/infosharedwiths");
    let promiseMembers = Rest.get("/api/users");
    let response = await Promise.all([promiseInfoSharedWiths, promiseMembers]);
    let state = { ...this.state };
    if (response[0].status === 200) {
      state.rows = response[0].data;
    }
    if (response[1].status === 200) {
      let unids = {};
      response[1].data.forEach((member) => {
        unids[member.unid] = member.FirstName + " " + member.LastName;
      });
      state.unids = unids;
    }
    this.setState(state);
  }

  render() {
    return (
      <Table
        schema={InfoSharedWithService.getSchema()}
        rows={this.state.rows}
        unids={this.state.unids}
        history={this.props.history}
      />
    );
  }
}
