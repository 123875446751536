import Service from "./Service";
import React from "react";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/calls`,
  viewTitle: "Incoming Calls",
  editTitle: "Incoming Call",
  columns: ["created", "fromphone", "flow", "voicemail"],
  fields: {
    created: {
      label: "Created",
      type: "datetime",
    },
    fromphone: {
      label: "From",
      type: "phone",
    },
    flow: {
      label: "Call Flow",
      style: "width: 300px",
      type: "calculated",
      value: (entry) => (
        <ul>
          {entry.sequence.map((seq, index) => (
            <li key={index}>{entry.callflow[seq].label}</li>
          ))}
        </ul>
      ),
    },
    voicemail: {
      label: "Voicemail",
      type: "calculated",
      value: (entry) => (
        <React.Fragment>
          {entry.transcription && typeof entry.transcription === "object" && (
            <div>{entry.transcription.msg.substring(0, 80) + "...."}</div>
          )}
          {entry.recordingurl && entry.recordingurl !== "null" && (
            <a
              href={entry.recordingurl + ".mp3"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Recording
            </a>
          )}
        </React.Fragment>
      ),
    },
  },
};

export default class CallService extends Service {
  static getSchema() {
    return schema;
  }
}
