import axios from "axios";
import decode from "jwt-decode";

function absoluteUrl(url) {
  if (
    window.location.href.startsWith("http://localhost:3000/") &&
    url.startsWith("/")
  ) {
    return "https://messaging.agolzer.com" + url;
  } else if (url.startsWith("/")) {
    return `${process.env.PUBLIC_URL}` + url;
  } else {
    return url;
  }
}

const TOKEN = "x-jwt-mvsda-org";

export default class Rest {
  static ACCESS_TYPES = [
    { unid: "user_read_partial", name: "User read partial" },
    { unid: "user_read_all", name: "User read all" },
    { unid: "user_write_all", name: "User write all" },
    { unid: "group_admin", name: "Group Admin" },
    { unid: "incomingcalls_read", name: "View Incoming Calls" },
    { unid: "callflow_admin", name: "Call Flow Admin" },
    { unid: "membergroups_admin", name: "Member Groups Admin" },
    { unid: "access_admin", name: "Access Admin" },
    { unid: "messages_read", name: "Messages read" },
  ];

  static requestOptions() {
    let ro = { headers: {} };
    if (Rest.getProfile()) {
      ro.headers[TOKEN] = localStorage.getItem(TOKEN);
    }
    return ro;
  }

  static get(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(absoluteUrl(url), Rest.requestOptions())
        .then((response) => {
          if (response.headers[TOKEN]) {
            localStorage.setItem(TOKEN, response.headers[TOKEN]);
          }
          resolve(response);
        })
        .catch((reason) => reject(reason));
    });
  }

  static post(url, data) {
    return new Promise((resolve, reject) =>
      axios
        .post(absoluteUrl(url), data, Rest.requestOptions())
        .then((response) => {
          if (response.headers[TOKEN]) {
            localStorage.setItem(TOKEN, response.headers[TOKEN]);
          }
          resolve(response);
        })
        .catch((reason) => {
          reject(reason);
        })
    );
  }

  static delete(url) {
    return new Promise((resolve, reject) =>
      axios.delete(absoluteUrl(url), Rest.requestOptions()).then((response) => {
        if (response.headers[TOKEN]) {
          localStorage.setItem(TOKEN, response.headers[TOKEN]);
        }
        resolve(response);
      })
    );
  }

  static getProfile() {
    let token = localStorage.getItem(TOKEN);
    if (token === null) {
      return undefined;
    }
    let ret = decode(token);
    return typeof ret === "string" ? JSON.parse(ret) : ret;
  }

  static setProfileEncoded(token) {
    // Saves user token to localStorage
    localStorage.setItem(TOKEN, token);
  }

  static getUserUnid() {
    return this.getProfile() && this.getProfile().unid;
  }

  /* pass in list of groups (separated by comma) and it returns true if user has access to any of those groups */
  static hasAccessGroup(group) {
    let groups = group.split(",");
    let accesses = Rest.getProfile() && Rest.getProfile().access;
    if (accesses) {
      for (let g = 0; g < groups.length; g++) {
        for (let i = 0; i < accesses.length; i++) {
          if (accesses[i] === groups[g]) {
            return true;
          }
        }
      }
    }
    return false;
  }

  static isLoggedIn() {
    return this.getProfile() ? true : false;
  }

  static logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem(TOKEN);
  }

  static getDisplayName() {
    let profile = Rest.getProfile();
    if (profile === undefined) {
      return undefined;
    } else {
      return profile.name;
    }
  }

  static getEmail() {
    let profile = Rest.getProfile();
    if (profile === undefined) {
      return undefined;
    } else {
      return profile.email;
    }
  }
}
