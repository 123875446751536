import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import Rest from "../../utils/rest";
import { Modal, Button } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";

export default class CallFlow extends Component {
  state = {
    dataUrl: undefined,
    pixelCrop: this.props.isfamily ? { unit: "px" } : { aspect: 1, unit: "px" },
    percentCrop: this.props.isfamily ? { unit: "%" } : { aspect: 1, unit: "%" },
    file: undefined,
    dialogStep: undefined,
    photoid: "",
  };

  setCrop(pixelCrop, percentCrop) {
    this.setState({ pixelCrop, percentCrop });
  }

  fileToDataUri = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  renderImage = () => {
    let width = this.props.size || (this.props.isfamily ? 500 : 150);
    let height = this.props.isfamily ? undefined : width;
    if (this.state.photoid || this.props.photoid) {
      return (
        <img
          style={{
            width: width + "px",
            height: height && height + "px",
            position: "relative",
            float: "left",
            overflow: "hidden",
            borderRadius: this.props.isfamily ? "0%" : "50%",
            margin: "5px",
          }}
          src={
            (this.props.isfamily ? "/api/families/" : "/api/users/") +
            this.props.memberid +
            "/photo/" +
            (this.state.photoid || this.props.photoid)
          }
          alt={this.props.name}
        />
      );
    } else {
      let name = this.props.name;
      if (name && name.split(" ").length > 1) {
        name = name.split(" ");
        name = name[0].substring(0, 1) + name[1].substring(0, 1);
      } else {
        name = name.substring(0, 1);
      }
      return (
        <div
          style={{
            width: width + "px",
            height: height && height + "px",
            position: "relative",
            float: "left",
            overflow: "hidden",
            borderRadius: this.props.isfamily ? "0%" : "50%",
            margin: "5px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: height && height + "px",
              backgroundColor: this.getBackgroundColor(),
              fontSize: width / 2 + "px",
              color: this.getForegroundColor(),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {name}
          </div>
        </div>
      );
    }
  };

  /* get background color based on member id */
  getBackgroundColor = () => {
    let memberid = this.props.memberid;
    if (!memberid || memberid.length !== 64) {
      return "#dddddd";
    }
    return "#" + memberid.substring(0, 6);
  };

  getForegroundColor = () => {
    let memberid = this.props.memberid;
    if (!memberid || memberid.length !== 64) {
      return "#222222";
    }
    let r = parseInt(memberid.substring(0, 2), 16);
    let g = parseInt(memberid.substring(2, 4), 16);
    let b = parseInt(memberid.substring(4, 6), 16);
    r = ((128 + r) % 256).toString(16);
    g = ((128 + g) % 256).toString(16);
    b = ((128 + b) % 256).toString(16);
    if (r.length === 1) r = "0" + r;
    if (g.length === 1) g = "0" + g;
    if (b.length === 1) b = "0" + b;
    return "#" + r + g + b;
  };

  renderDialog = () => {
    if (this.state.dialogStep) {
      return (
        <Modal
          show={true}
          backdrop="static"
          style={{ position: "absolute" }}
          onHide={() => this.setState({ dialogStep: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.dialogStep === "choose"
                ? "Select Photo"
                : "Crop Photo"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.dialogStep === "choose" && (
              <input
                type="file"
                onChange={async (event) => {
                  let file = event.target.files[0] || null;
                  if (!file) {
                    this.setState({ dataUrl: file });
                    return;
                  }

                  let dataUrl = await this.fileToDataUri(file);
                  this.setState({ dataUrl, file, dialogStep: "crop" });
                }}
              />
            )}
            {this.state.dialogStep === "crop" && (
              <ReactCrop
                src={this.state.dataUrl}
                crop={this.state.percentCrop}
                circularCrop={!this.props.isfamily}
                onChange={(pixelCrop, percentCrop) =>
                  this.setCrop(pixelCrop, percentCrop)
                }
              />
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ dialogStep: "" })}
            >
              Cancel
            </Button>
            <React.Fragment>
              <canvas
                id="myCanvas"
                width={this.props.isfamily ? this.state.pixelCrop.width : 150}
                height={this.props.isfamily ? this.state.pixelCrop.height : 150}
                style={{ display: "none" }}
              ></canvas>
              <Button
                disabled={!this.state.dataUrl || !this.state.percentCrop.width}
                variant="primary"
                onClick={async () => {
                  let photo = await this.getCroppedImg(
                    this.state.dataUrl,
                    this.state.percentCrop,
                    this.props.isfamily ? this.state.pixelCrop.width : 150,
                    this.props.isfamily ? this.state.pixelCrop.height : 150
                  );
                  let response = await Rest.post(
                    (this.props.isfamily ? "/api/families/" : "/api/users/") +
                      this.props.memberid +
                      "/photo",
                    {
                      photo: photo,
                    }
                  );
                  if (response.status === 200) {
                    this.setState({
                      dialogStep: "",
                      photoid: response.data.unid,
                    });
                  }
                }}
              >
                Upload
              </Button>
            </React.Fragment>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null;
    }
  };

  render = () => {
    if (this.props.readonly) {
      return this.renderImage();
    } else {
      return (
        <React.Fragment>
          <button
            style={{ float: "left", border: "0px", backgroundColor: "#FFF" }}
            onClick={() => this.setState({ dialogStep: "choose" })}
          >
            {this.renderImage()}
          </button>
          {this.renderDialog()}
        </React.Fragment>
      );
    }
  };

  getCroppedImg = async (image, percentCrop, width, height) => {
    return new Promise((resolve, reject) => {
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      var imageObj = new Image();
      imageObj.onload = function () {
        // draw cropped image
        context.drawImage(
          imageObj,
          (percentCrop.x * imageObj.width) / 100,
          (percentCrop.y * imageObj.height) / 100,
          (percentCrop.width * imageObj.width) / 100,
          (percentCrop.height * imageObj.height) / 100,
          0,
          0,
          width,
          height
        );
        resolve(canvas.toDataURL("image/jpeg", 1.0));
      };
      imageObj.src = image;
    });
  };
}
