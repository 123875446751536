import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Table from "./common/Table";
import ContactExchangeService from "../service/ContactExchangeService";

export default class ContactExchanges extends Component {
  state = { contactExchanges: [], unids: {} };
  async componentDidMount() {
    let promiseContactExchanges = Rest.get("/api/contactexchanges");
    let promiseMembers = Rest.get("/api/users");
    let response = await Promise.all([promiseContactExchanges, promiseMembers]);
    let state = { contactExchanges: [], unids: {} };
    if (response[0].status === 200) {
      state.contactExchanges = response[0].data;
      state.contactExchanges.sort((m1, m2) =>
        m1.created < m2.created ? 1 : m1.created > m2.created ? -1 : 0
      );
    }
    if (response[1].status === 200) {
      response[1].data.forEach((member) => {
        state.unids[member.unid] = member.FirstName + " " + member.LastName;
      });
    }
    this.setState(state);
  }

  render() {
    return (
      <Table
        schema={ContactExchangeService.getSchema()}
        rows={this.state.contactExchanges}
        unids={this.state.unids}
        history={this.props.history}
      />
    );
  }
}
